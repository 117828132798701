<template>
  <component
    :is="tag"
    :class="buttonClasses"
    :type="type"
    v-bind="{ ...$attrs, ...linkProps }"
    @click="handleClick"
  >
    <slot></slot>
  </component>
</template>

<script setup>
import { computed, watch } from 'vue'

// Props
const props = defineProps({
  size: { type: String, default: undefined },
  variant: {
    type: String,
    default: 'primary',
  },
  ripple: { type: Number, default: undefined },
  block: Boolean,
  to: { type: [String, Object], default: undefined },
  pressed: Boolean,
  toggle: { type: [String, Boolean], default: undefined },
  href: { type: String, default: undefined },
  type: {
    type: String,
    default: 'button',
  },
})

const emits = defineEmits(['click', 'update:pressed'])

// Computed properties
const tag = computed(() => {
  if (props.to) {
    return 'router-link'
  } if (props.href) {
    return 'a'
  }
  return 'button'
})

const buttonClasses = computed(() => {
  const classes = ['btn']

  if (props.variant) {
    classes.push(`btn-${props.variant}`)
  }
  if (props.size) {
    classes.push(`btn-${props.size}`)
  }
  if (props.block) {
    classes.push('btn-block')
  }
  if (props.pressed) {
    classes.push('active')
  }

  // Placeholder for v-ripple directive implementation
  // if (props.ripple) {
  //   classes.push(`ripple-${props.ripple}`);
  // }

  return classes
})

// Watchers
watch(() => props.pressed, newVal => {
  emits('update:pressed', newVal)
})

const linkProps = computed(() => {
  if (props.to) return { to: props.to }
  if (props.href) {
    const href = typeof props.toggle === 'boolean' ? `#${props.href}` : props.href
    return { href }
  }
  return {}
})

// Methods
const handleClick = event => {
  emits('click', event)
}
</script>
