<template>
  <vue-feather :type="iconComputed" :size="size" v-bind="attrs" :style="{color}" />
  <span v-if="badge" :class="['badge badge-up badge-pill', badgeClasses]">
    {{ badge }}
  </span>
</template>

<script setup>
import { toRefs, useAttrs, computed } from 'vue'
import VueFeather from 'vue-feather'

defineOptions({
  name: 'FeatherIcon',
  inheritAttrs: false,
})

const props = defineProps({
  icon: {
    required: true,
    type: [String, Object],
  },
  size: {
    type: String,
    default: '14',
  },
  badge: {
    type: [String, Object, Number],
    default: null,
  },
  badgeClasses: {
    type: [String, Object, Array],
    default: 'badge-primary',
  },
  color: {
    type: String,
    default: null,
  },
})

const { icon, size, badge, badgeClasses } = toRefs(props)

const attrs = useAttrs()

const iconComputed = computed(() => {
  let result = icon.value.substring(0, icon.value.length - 4) // Remove Icon in the end
  result = result.replace(/([a-z0-9])([A-Z0-9])/g, '$1-$2').toLowerCase()

  return result
})

</script>

<style lang="scss">
svg.feather {
  outline: none;
}
.dropdown-item .vue-feather {
  overflow: inherit !important;
}
.vue-feather__content {
  width: auto !important;
  min-height: 11px !important;
}
.dropdown-item .vue-feather__content {
  display: inherit !important;
}
</style>
