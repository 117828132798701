import { cloneDeep } from 'lodash'
import { getField, updateField } from 'vuex-map-fields'
import { fetchTripInfosRequest, fetchTripsRequest } from '@/request/globalApi/requests/tripRequest'
import { fetchTripStepCostDetailsRequest } from '@/request/globalApi/requests/tripStepRequests'

export default {
  namespaced: true,
  state: {
    trip: null,
    tripLoading: false,
    trips: [],
    tripsLoading: false,
    tripsTotalItems: 0,
    tripStepEvents: {},
    costDetailLoading: false,
    // Paramètrage de la liste
    paginate: { page: 1, numberOfItemsPerPage: 10 },
    filter: {
      name: null,
      customer: null,
      start: null,
      end: null,
      flightMission: null,
      flightType: null,
      movementType: null,
      organizationAircraftType: null,
      organizationAircraft: null,
      airport: null,
      organizationHelicopter: null,
      organizationCrewMember: null,
      organizationAirport: null,
      externalCalendarHelicopters: [],
    },
  },
  getters: {
    getField,
    trip: state => state.trip,
    tripLoading: state => state.tripLoading,
    trips: state => state.trips,
    tripsLoading: state => state.tripsLoading,
    tripsTotalItems: state => state.tripsTotalItems,
    tripStepEvents: state => state.tripStepEvents,
    costDetailLoading: state => state.costDetailLoading,
    tripStepCharges: state => (tripId, tripStepId) => {
      const trip = state.trips.find(({ id }) => id === tripId)
      const tripStep = trip.tripSteps.find(({ id }) => id === tripStepId)
      if (tripStep && tripStep.costDetails) {
        return tripStep.costDetails.charges
      }
      return []
    },
    filtersListCount: state => {
      let count = 0
      const filter = { ...state.filter }
      delete filter.externalCalendarHelicopters

      Object.values(filter).forEach(filt => {
        if (Array.isArray(filt) && filt.length > 0) {
          count += 1
        }
        if (!Array.isArray(filt) && filt) {
          count += 1
        }
      })
      return count
    },
    filtersCalendarCount: state => {
      let count = 0
      // Retire les filtres start et end qui ne sont pas dans le calendrier sans les supprimer du state
      const filter = { ...state.filter }
      delete filter.start
      delete filter.end
      delete filter.airport

      Object.values(filter).forEach(filt => {
        if (Array.isArray(filt) && filt.length > 0) {
          count += 1
        }
        if (!Array.isArray(filt) && filt) {
          count += 1
        }
      })
      return count
    },
  },
  mutations: {
    updateField,
    SET_TRIP(state, trip) {
      state.trip = trip
    },
    SET_TRIP_LOADING(state, loading) {
      state.tripLoading = loading
    },
    SET_TRIPS(state, trips) {
      state.trips = trips
    },
    ADD_TRIP(state, trip) {
      state.trips.push(trip)
    },
    SET_TRIPS_LOADING(state, tripsLoading) {
      state.tripsLoading = tripsLoading
    },
    SET_TRIPS_TOTAL_ITEMS(state, tripsTotalItems) {
      state.tripsTotalItems = tripsTotalItems
    },
    SET_TRIP_STEP_EVENTS(state, tripStepEvents) {
      state.tripStepEvents = tripStepEvents
    },
    SET_COST_DETAIL_LOADING(state, loading) {
      state.costDetailLoading = loading
    },
    SET_PAGINATION: (state, pagination) => {
      state.pagination = pagination
    },
    CLEAR_FILTERS: state => {
      Object.keys(state.filter).forEach(key => {
        if (key === 'externalCalendarHelicopters') {
          state.filter[key] = []
        } else {
          state.filter[key] = null
        }
      })
    },
  },
  actions: {
    async fetchTrip({ state, dispatch, commit }, { tripId, forced }) {
      if (!state.trip || forced) {
        commit('SET_TRIP_LOADING', true)
        return fetchTripInfosRequest(tripId)
          .then(response => {
            commit('SET_TRIP', response.data.trip)
            // Ajout du trip dans la liste des trips
            commit('ADD_TRIP', response.data.trip)

            dispatch('loadTripStepEventsForSeller', response.data.trip)
            return response.data.trip
          })
          .finally(() => {
            commit('SET_TRIP_LOADING', false)
          })
      }
      return state.trip
    },
    async fetchTrips({ state, dispatch, commit }) {
      commit('SET_TRIPS_LOADING', true)
      return fetchTripsRequest({
        ...state.paginate,
        ...state.filter,
      })
        .then(response => {
          const { trips, totalItems } = response.data
          commit('SET_TRIPS_TOTAL_ITEMS', totalItems)
          commit('SET_TRIPS', trips)
          // RESET des trip step events au rechargement de la liste pour prendre en compte les filtres
          commit('SET_TRIP_STEP_EVENTS', {})

          state.trips.forEach(trip => {
            dispatch('loadTripStepEventsForSeller', trip)
          })
        })
        .finally(() => {
          commit('SET_TRIPS_LOADING', false)
        })
    },
    loadTripStepEventsForSeller({ state, commit, rootGetters }, trip) {
      const tripStepEvents = cloneDeep(state.tripStepEvents)
      const selectedOrganizationId = rootGetters['auth/selectedOrganizationId']
      trip.tripSteps.forEach(tripStep => {
        if (tripStep.className === 'TripFlight') {
          let departureProviders = tripStep.flightDeparture.tripStepProviders
          if (typeof tripStep.flightDeparture.tripStepProviders === 'object') {
            departureProviders = Object.values(tripStep.flightDeparture.tripStepProviders)
          }

          let arrivalProviders = tripStep.flightArrival.tripStepProviders
          if (typeof tripStep.flightArrival.tripStepProviders === 'object') {
            arrivalProviders = Object.values(tripStep.flightArrival.tripStepProviders)
          }

          const isDepartureSeller = departureProviders.length > 0 && departureProviders.findIndex(tripStepProvider => tripStepProvider.organizationId === selectedOrganizationId) !== -1
          const isArrivalSeller = arrivalProviders.length > 0 && arrivalProviders.findIndex(tripStepProvider => tripStepProvider.organizationId === selectedOrganizationId) !== -1

          if (tripStep.flightDeparture.visibleInList && isDepartureSeller) {
            if (!tripStepEvents.hasOwnProperty(trip.id)) {
              tripStepEvents[trip.id] = []
            }

            // Vérification que l'événement n'est pas déjà présent dans la liste
            if (tripStepEvents[trip.id].findIndex(event => event.id === tripStep.flightDeparture.id) !== -1) {
              // Si l'événement est déjà présent, on le supprime
              tripStepEvents[trip.id] = tripStepEvents[trip.id].filter(event => event.id !== tripStep.flightDeparture.id)
            }

            tripStepEvents[trip.id].push({
              ...tripStep.flightDeparture,
              tripStep,
            })
          }

          if (tripStep.flightArrival.visibleInList && isArrivalSeller) {
            if (!tripStepEvents.hasOwnProperty(trip.id)) {
              tripStepEvents[trip.id] = []
            }

            // Vérification que l'événement n'est pas déjà présent dans la liste
            if (tripStepEvents[trip.id].findIndex(event => event.id === tripStep.flightArrival.id) !== -1) {
              // Si l'événement est déjà présent, on le supprime
              tripStepEvents[trip.id] = tripStepEvents[trip.id].filter(event => event.id !== tripStep.flightArrival.id)
            }

            tripStepEvents[trip.id].push({
              ...tripStep.flightArrival,
              tripStep,
            })
          }
        } else if (tripStep.transferDeparture.visibleInList) {
          if (!tripStepEvents.hasOwnProperty(trip.id)) {
            tripStepEvents[trip.id] = []
          }

          // Vérification que l'événement n'est pas déjà présent dans la liste
          const tripStepEventIndex = tripStepEvents[trip.id].findIndex(event => event.id === tripStep.transferDeparture.id)
          if (tripStepEventIndex !== -1) {
            // Si l'événement est déjà présent, on le supprime
            tripStepEvents[trip.id] = tripStepEvents[trip.id].filter(event => event.id !== tripStep.transferDeparture.id)
          }

          tripStepEvents[trip.id].push({
            ...tripStep.transferDeparture,
            tripStep,
          })
        }
      })

      commit('SET_TRIP_STEP_EVENTS', tripStepEvents)
    },
    fetchCostDetailsByTripStepId({ state, commit }, params) {
      // Récupération du trip
      const tripIndex = state.trips.findIndex(trip => trip.id === params.tripId)
      const trip = state.trips[tripIndex]

      // Récupération du tripStep
      const tripStepIndex = trip.tripSteps.findIndex(tripStep => tripStep.id === params.tripStepId)
      const tripStep = trip.tripSteps[tripStepIndex]

      // Si le loading est déjà en cours, on attend qu'il se termine et retourne la promesse
      if (state.costDetailLoading) {
        return new Promise(resolve => {
          const interval = setInterval(() => {
            if (!state.costDetailLoading) {
              clearInterval(interval)
              resolve(tripStep.costDetails)
            }
          }, 100)
        })
      }

      commit('SET_COST_DETAIL_LOADING', true)
      return fetchTripStepCostDetailsRequest(params.tripStepId)
        .then(r => {
          // Assignation du tripStep dans le trip et du trip dans le state
          tripStep.costDetails = r.data
          trip.tripSteps[tripStepIndex] = tripStep
          trip.chargesLoaded = true
          state.trips[tripIndex] = trip
        }).finally(() => {
          commit('SET_COST_DETAIL_LOADING', false)
        })
    },
    clearFilters({ commit }) {
      commit('CLEAR_FILTERS')
    },
  },
}
