<template>
  <div :class="['h-100', skinClasses]">
    <LayoutVertical v-show="i18nLoaded" v-if="layout() === 'layout-vertical'" />
    <LayoutFull v-show="i18nLoaded" v-else><router-view /></LayoutFull>
  </div>

  <div id="wrapperPopup" />
  <msg-box-confirm />
</template>

<script>
// This will be populated in `beforeCreate` hook
import { watch, ref, defineAsyncComponent } from 'vue'
import { $themeColors, $themeConfig } from '@themeConfig'
import MsgBoxConfirm from '@/components/MsgBoxConfirm.vue'
import useAppConfig from '@/composables/useAppConfig'
import { initI18n } from '@/libs/i18n'
import { setFlatPickrLocale } from '@/libs/flat-pickr'

import { useWindowSize, useCssVar } from '@vueuse/core'

import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default {
  components: {
    MsgBoxConfirm,
    // Exemple pour un layout asynchrone
    LayoutVertical: defineAsyncComponent(() =>
      import('@/layouts/layout-vertical/LayoutVertical.vue')
    ),
    LayoutFull: defineAsyncComponent(() =>
      import('@/layouts/layout-full/LayoutFull.vue')
    ),
  },
  watch: {
    '$i18n.locale': {
      handler(value) {
        setFlatPickrLocale(value)
      },
    },
  },
}
</script>

<script setup>
import { useToaster } from '@/composables/useToaster'

const { t } = useI18n()
const route = useRoute()
const store = useStore()
const { toaster } = useToaster()

// Set colors in theme
const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

for (let i = 0, len = colors.length; i < len; i++) {
  $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
}

// Set RTL
const { isRTL } = $themeConfig.layout
document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')

const { skin, skinClasses } = useAppConfig()
const i18nLoaded = ref(false)

const layout = () => {
  if (route.meta.layout === 'full') return 'layout-full'
  return 'layout-vertical'
}

initI18n().then(() => {
  const loadingEl = document.getElementById('loading-bg')
  loadingEl.classList.add('d-none')
  i18nLoaded.value = true
})

// If skin is dark when initialized => Add class to body
if (skin.value === 'dark') document.body.classList.add('dark-layout')

// Set Window Width in store
store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
const { width: windowWidth } = useWindowSize()
watch(windowWidth, val => {
  store.commit('app/UPDATE_WINDOW_WIDTH', val)
})

if (localStorage.getItem('showConflictNotification') === 'true') {
  setTimeout(() => {
    // Afficher la notification
    toaster(t('alert.conflict.notification'), 'danger', 'AlertTriangleIcon')

    // Supprimer l'indicateur de LocalStorage
    localStorage.removeItem('showConflictNotification');
  }, 1000);
}
</script>
