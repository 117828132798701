export default {
  namespaced: true,
  state: {
    tripStepEventHangarSearch: {},
  },
  mutations: {
    SET_START_DATE(state, { tripStepEventId, startDate }) {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        state.tripStepEventHangarSearch[tripStepEventId] = {
          startDate: '',
          startTime: '',
          endDate: '',
          endTime: '',
        }
      }
      state.tripStepEventHangarSearch[tripStepEventId].startDate = startDate
      localStorage.setItem('hangarSearch_tripStepEventHangarSearch', JSON.stringify(state.tripStepEventHangarSearch))
    },
    SET_START_TIME(state, { tripStepEventId, startTime }) {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        state.tripStepEventHangarSearch[tripStepEventId] = {
          startDate: '',
          startTime: '',
          endDate: '',
          endTime: '',
        }
      }
      state.tripStepEventHangarSearch[tripStepEventId].startTime = startTime
      localStorage.setItem('hangarSearch_tripStepEventHangarSearch', JSON.stringify(state.tripStepEventHangarSearch))
    },
    SET_END_DATE(state, { tripStepEventId, endDate }) {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        state.tripStepEventHangarSearch[tripStepEventId] = {
          startDate: '',
          startTime: '',
          endDate: '',
          endTime: '',
        }
      }
      state.tripStepEventHangarSearch[tripStepEventId].endDate = endDate
      localStorage.setItem('hangarSearch_tripStepEventHangarSearch', JSON.stringify(state.tripStepEventHangarSearch))
    },
    SET_END_TIME(state, { tripStepEventId, endTime }) {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        state.tripStepEventHangarSearch[tripStepEventId] = {
          startDate: '',
          startTime: '',
          endDate: '',
          endTime: '',
        }
      }
      state.tripStepEventHangarSearch[tripStepEventId].endTime = endTime
      localStorage.setItem('hangarSearch_tripStepEventHangarSearch', JSON.stringify(state.tripStepEventHangarSearch))
    },
    SET_TRIP_STEP_EVENT_HANGAR_SEARCH(state, payload) {
      state.tripStepEventHangarSearch = payload
    },
  },
  actions: {
    setTripStepEventHangarSearch({ commit, state }, tripStepEventId) {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        if (localStorage.getItem('hangarSearch_tripStepEventHangarSearch')) {
          commit('SET_TRIP_STEP_EVENT_HANGAR_SEARCH', JSON.parse(localStorage.getItem('hangarSearch_tripStepEventHangarSearch')))
        }
      }
    },
  },
  getters: {
    getStartDateByEvent: state => tripStepEventId => {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        return ''
      }

      return state.tripStepEventHangarSearch[tripStepEventId].startDate
    },
    getStartTimeByEvent: state => tripStepEventId => {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        return ''
      }

      return state.tripStepEventHangarSearch[tripStepEventId].startTime
    },
    getEndDateByEvent: state => tripStepEventId => {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        return ''
      }

      return state.tripStepEventHangarSearch[tripStepEventId].endDate
    },
    getEndTimeByEvent: state => tripStepEventId => {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        return ''
      }

      return state.tripStepEventHangarSearch[tripStepEventId].endTime
    },
  },
}
