<template>
  <transition
    name="v-height-fade"
    mode="out-in"
    @before-enter="beforeEnter"
    @enter="enter"
    @leave="leave"
  >
    <div
      v-if="visible && show"
      :class="['alert', `alert-${variant}`, { 'ripple': vRipple }]"
      role="alert"
    >
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {

  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    vHeightFade: {
      type: Boolean,
      default: false,
    },
    vRipple: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      visible: true,
    }
  },
  methods: {
    beforeEnter(el) {
      el.style.height = '0'
      el.style.opacity = '0'
    },
    enter(el, done) {
      el.style.transition = 'height 0.3s ease, opacity 0.3s ease'
      el.style.height = `${el.scrollHeight}px`
      el.style.opacity = '1'
      done()
    },
    leave(el, done) {
      el.style.height = '0'
      el.style.opacity = '0'
      done()
    },
  },
}
</script>

<style>
.v-height-fade-enter-active, .v-height-fade-leave-active {
  transition: opacity 0.5s;
}
.v-height-fade-enter, .v-height-fade-leave-to /* .v-height-fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.ripple:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}
.ripple:active:after {
  transition: width 0.3s ease, height 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
  width: 200%;
  height: 200%;
  opacity: 0;
  transform: scale(0, 0) translate(-50%, -50%);
}
</style>
