<template>
  <component :is="label ? 'b-form-group' : 'div'" :label="label" :label-for="id" :class="[{ 'validation-required': isRequired }, classGroup]">
    <b-overlay :show="overlay">
      <b-input-group>
        <flat-pickr
          :id="id"
          :class="[
            'form-control rounded-left',
            clearable && !disabled ? 'rounded-right-0' : 'rounded-right',
            { 'is-valid': stateComputed === true, 'is-invalid': stateComputed === false }
          ]"
          :name="name || label"
          :model-value="value"
          :placeholder="placeholder !== false ? placeholder || label : ''"
          :required="isRequired"
          :disabled="disabled"
          :key="key"
          :config="{
            ...config,
            altInput: altInputComputed,
            allowInput: allowInputComputed,
            locale: $i18n.locale,
            time_24hr: time_24hr,
            disableMobile: true,

            maxDate: maxComputed,
            minDate: minComputed,
            noCalendar: noCalendar,
            dateFormat: dateFormatComputed,
            altFormat: altFormatComputed,
            enableTime: enableTime,
            disable: disable,
            onOpen: onOpen,
            onMonthChange: onMonthChange,
            onChange: onChange,
            onValueUpdate: onValueUpdate,
          }"
          v-bind="$attrs"
        />
        <b-input-group-append>
          <button v-if="clearable && !disabled" class="btn btn-sm btn-outline-primary text-danger" type="button" @click="value = null">
            <feather-icon icon="XIcon" />
          </button>
        </b-input-group-append>
      </b-input-group>
      <span v-if="!hideErrors && errors[0]" class="text-danger">{{ deleteFieldText(errors[0]) }}</span>
      <slot />
    </b-overlay>
  </component>
</template>

<script>
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'AppDatepicker',
  inheritAttrs: false,
})
</script>

<script setup>
import { useFlatpickr } from '@/composables/useFlatpickr'
import { useField } from 'vee-validate'
import {ref, watch} from "vue";
import { useI18n } from 'vue-i18n'

const { config } = useFlatpickr()

const props = defineProps({
  modelValue: { type: [Number, String, Date], default: undefined },
  id: { type: String, default: '' },
  name: { type: String, default: '' },
  vvName: { type: String, default: undefined },
  classGroup: { type: String, default: undefined },
  label: { type: String, default: '' },
  placeholder: { type: String, default: undefined },
  overlay: { type: Boolean, default: false },
  rules: { type: String, default: '' },
  required: { type: Boolean, default: false },
  hideErrors: { type: Boolean, default: false },
  state: { type: Boolean, default: undefined },
  max: { type: [String, Number, Date], default: undefined },
  min: { type: [String, Number, Date], default: undefined },
  noCalendar: { type: Boolean, default: false },
  enableTime: { type: Boolean, default: false },
  dateFormat: { type: String, default: undefined },
  altFormat: { type: String, default: undefined },
  disabled: { type: Boolean, default: false },
  disable: { type: Array, default: () => [] },
  // eslint-disable-next-line vue/prop-name-casing
  time_24hr: { type: Boolean, default: true },
  clearable: { type: Boolean, default: false },
  otherSelectedDate: { type: [String, Number, Date], default: undefined },
})

const { locale } = useI18n()

const isRequired = computed(() => props.rules.includes('required') || props.required)

const maxComputed = computed(() => {
  if (!props.max || props.max === 'Invalid date') return undefined
  return new Date(props.max)
})

const minComputed = computed(() => {
  if (!props.min || props.min === 'Invalid date') return undefined
  return new Date(props.min)
})

const computedRules = computed(() => {
  let normalizeRules = props.rules
  if (isRequired.value && !normalizeRules.includes('required')) {
    if (!normalizeRules) normalizeRules = 'required'
    else normalizeRules += '|required'
  }
  return normalizeRules
})

const { errors, value, validate } = useField(() => props.id, computedRules, {
  syncVModel: true,
})

const stateComputed = computed(() => {
  if (errors[0]) return false
  return props.state
})

const dateFormatComputed = computed(() => {
  if (props.dateFormat) return props.dateFormat

  // Dans le cas où on a un timepicker (sans calendrier) on ne veut pas de date
  if (props.enableTime && props.noCalendar) return 'H:i'

  return 'Y-m-dTH:i:S+00:00'
})

const altFormatComputed = computed(() => {
  if (props.altFormat) return props.altFormat

  // Dans le cas où on a un timepicker (sans calendrier) on ne veut pas de date
  if (props.enableTime && props.noCalendar) return null

  // Dans le cas où l'on a pas de timepicker on ne veut pas d'heure
  if (!props.enableTime) return 'D, M J Y'

  return 'D, M J Y H:i'
})

// Le alt input ne peut être défini en mode timepicker seulement pour cause d'une erreur sur le parsing de la date
const altInputComputed = computed(() => !(props.enableTime && props.noCalendar))

const allowInputComputed = computed(() => false)

// Trick to prevent 'The {field} field must be ...'
// But bad solution about langs, I suppose...
// vvName with default to ' ' seems work, but break the validator
const deleteFieldText = msg => {
  const reg = new RegExp(props.id, 'i')
  if (!props.vvName) return msg.replace(reg, '')
  if (props.vvName === true) return msg.replace(reg, props.label || '')
  return msg.replace(reg, props.vvName)
}

const calculateOtherSelectedDate = (selectedDates, dateStr, instance) => {
  if (props.otherSelectedDate) {
    const otherSelectedDate = new Date(props.otherSelectedDate)
    if (otherSelectedDate) {
      if (instance) {
        const dayContainer = instance.calendarContainer.querySelector('.dayContainer')
        if (dayContainer) {
          const days = dayContainer.querySelectorAll('.flatpickr-day')

          for (const day of days) {
            if (day.dateObj.getDate() === otherSelectedDate.getDate() && day.dateObj.getMonth() === otherSelectedDate.getMonth() && day.dateObj.getFullYear() === otherSelectedDate.getFullYear()) {
              day.classList.add('other-selected-day')
              break
            }
          }
        }
      }
    }
  }
}

const onOpen = calculateOtherSelectedDate
const onMonthChange = calculateOtherSelectedDate
const onChange = calculateOtherSelectedDate
const onValueUpdate = calculateOtherSelectedDate

const key = ref(0)

watch(locale, newLocale => {
  if (newLocale) {
    key.value++
    validate()
  }
})

</script>

<style>
.flatpickr-calendar {
  border-color: #DCC181;
  border: 1px solid #DCC181;
}

.other-selected-day:not(.selected) {
  background-color: #e3e3e3 !important;
  color: #4b4b4b !important;
}

.flatpickr-input[readonly] {
  background-color: inherit !important;
}

.flatpickr-disabled {
  background-color: #f6f6f6 !important;
}

.flatpickr-disabled {
  background-color: #f6f6f6 !important;
  color: #4b4b4b !important;

  &:not(.today) {
    border-radius: 0;
  }
}
.flatpickr-calendar .flatpickr-day {
  min-width: 43.98px; /* Dimension exacte des en-têtes du calendrier */
}
.dayContainer {
  -webkit-justify-content: center !important;
  justify-content: center !important;
}
.flatpickr-calendar.hasTime.noCalendar {
  width: 250px !important;
}
</style>
