import { computed } from 'vue'
import store from '@/store/store'

// Note: mapState, mapGetters and mapActions (vuex) doesn't work in composition api. Use this composables instead. Same pattern.

export const useMapState = (module, states) => {
  const mapState = {}
  states?.forEach(state => {
    mapState[state] = computed(() => store.state[module][state])
  })
  return mapState
}

export const useMapGetters = (module, getters) => {
  const mapGetters = {}
  getters?.forEach(getter => {
    mapGetters[getter] = computed(() => store.getters[`${module}/${getter}`])
  })
  return mapGetters
}

export const useMapActions = (module, actions) => {
  const mapActions = {}
  actions?.forEach(action => {
    mapActions[action] = (...payload) => store.dispatch(`${module}/${action}`, ...payload)
  })
  return mapActions
}
