import { defineAbility } from '@casl/ability';

export default defineAbility((can, cannot) => {
  const abilities = JSON.parse(localStorage.getItem('abilities')) || [
    { action: 'read', subject: 'DEFAULT' },
  ];

  abilities.forEach((ability) => {
    can(ability.action, ability.subject);
  });
});