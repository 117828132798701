export default [
  {
    path: '/parameters/products',
    name: 'parametersProducts',
    component: () => import('@/views/parameters/Parameters.vue'),
    meta: {
      pln: 2,
      action: 'OPERATOR_PARAMETER_VIEW',
      title: 'parameters.products.title',
      breadcrumb: [
        {
          text: 'navigation.parameters',
          active: true,
        },
        {
          text: 'parameters.products.title',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parameters/organizations',
    name: 'parametersOrganizations',
    component: () => import('@/views/parameters/Parameters.vue'),
    meta: {
      pln: 2,
      action: 'ACCOUNTING_VIEW',
      title: 'parameters.organizations.title',
      breadcrumb: [
        {
          text: 'navigation.parameters',
          active: true,
        },
        {
          text: 'parameters.organizations.title',
          active: true,
        },
      ],
    },
  },
]
