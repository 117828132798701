import { getField, updateField } from 'vuex-map-fields'
import { fetchPaymentMethodsRequest } from '@/request/globalApi/requests/paymentMethodRequests'

export default {
  namespaced: true,
  state: {
    paymentMethodsLoading: false,
    paymentMethods: [],
  },

  getters: {
    getField,

    paymentMethodTypeById: state => id => {
      const paymentMethod = state.paymentMethods.find(item => item.id === id)
      return paymentMethod?.type || null
    },
  },

  mutations: {
    updateField,

    SET_PAYMENT_METHODS_LOADING: (state, loading) => {
      state.paymentMethodsLoading = loading
    },

    SET_PAYMENT_METHODS: (state, paymentMethods) => {
      state.paymentMethods = paymentMethods
    },
  },

  actions: {
    fetchPaymentMethods({ commit, state }, refresh) {
      if (refresh || !state.paymentMethods.length) {
        commit('SET_PAYMENT_METHODS_LOADING', true)
        fetchPaymentMethodsRequest({ params: { getAllItems: true } })
          .then(response => {
            commit('SET_PAYMENT_METHODS', response.data.paymentMethods)
          })
          .finally(() => {
            commit('SET_PAYMENT_METHODS_LOADING', false)
          })
      }
    },
  },
}
