import i18n from '@/libs/i18n'
import { configure, defineRule } from 'vee-validate'

import { localize } from '@vee-validate/i18n'
import en from '@vee-validate/i18n/dist/locale/en.json'
import fr from '@vee-validate/i18n/dist/locale/fr.json'

import * as rules from '@vee-validate/rules'

import {
  validatorPositive,
  validatorStrictPositive,
  validatorUrl,
  validatorPassword,
  validatorCreditCard,
  validatorIban,
  validatorTel,
  validatorDecimalOrInt,
  validatorPercentage,
} from './vee-validate-specific-validators'

// Install and set default locale to en
configure({
  generateMessage: localize({
    en,
    fr,
  }),
})

localize(en)

// set locale
// eslint-disable-next-line import/prefer-default-export
export function setValidationLocale(code) {
  localize(code)
}

// GENERAL RULES
// all this rules are accessible directly in component
// SEE https://vee-validate.logaretm.com/v3/guide/rules.html#rules
// import in component like this
// <Field rules="required|numeric" ...

Object.keys(rules).forEach(rule => {
  defineRule(rule, rules[rule])
})

defineRule('decimalOrInt', value => {
  const result = validatorDecimalOrInt(value)
  if (!result) {
    return i18n.global.t('validator.decimal_or_int')
  }

  return result
})

defineRule('percentage', value => {
  const result = [null, undefined].includes(value) || validatorPercentage(value)
  if (!result) {
    return i18n.global.t('validator.percentage')
  }

  return result
})

// SPECIFIC RULES
// import in component like this
// import { positive } from '@/libs/vee-validate'
// methods: {positive,}

defineRule('positive', value => {
  const result = validatorPositive(value)
  if (!result) {
    return i18n.global.t('validator.positive')
  }

  return result
})

defineRule('strict_positive', value => {
  const result = validatorStrictPositive(value)
  if (!result) {
    return i18n.global.t('validator.strict_positive')
  }

  return result
})

defineRule('credit-card', value => {
  const result = validatorCreditCard(value)
  if (!result) {
    return i18n.global.t('validator.credit_card')
  }

  return result
})

defineRule('password', value => {
  const result = validatorPassword(value)
  if (!result) {
    return i18n.global.t('validator.password')
  }

  return result
})

defineRule('url', value => {
  const result = validatorUrl(value)
  if (!result) {
    return i18n.global.t('validator.url')
  }

  return result
})

defineRule('iban', value => {
  const result = validatorIban(value)
  if (!result) {
    return i18n.global.t('validator.invalid_iban.message', localStorage.getItem('lang'))
  }

  return result
})

defineRule('tel', value => {
  const result = validatorTel(value)
  if (!result) {
    return i18n.global.t('validator.invalid_tel.message', localStorage.getItem('lang'))
  }

  return result
})
