export default [
  {
    path: '/aircrafts',
    name: 'aircrafts',
    component: () => import('@/views/aircraft/Aircrafts.vue'),
    meta: {
      title: 'navigation.aircrafts',
      action: 'OPERATOR_AIRCRAFT_VIEW',
      breadcrumb: [
        {
          text: 'navigation.aircrafts',
          active: true,
        },
      ],
    },
  },
]
