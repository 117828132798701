<template>
  <b-v-tooltip :interactive="interactive">
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData || {}" />
    </template>
  </b-v-tooltip>
</template>

<script>
export default {
  name: 'BTooltipExtended',
}
</script>

<script setup>
import { BTooltip as BVTooltip } from 'bootstrap-vue-next'

defineProps({
  interactive: {
    type: Boolean,
    default: true,
  },
})
</script>
