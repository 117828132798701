import offerInformation from './offerChildInformation'
import offerCommission from './offerChildCommission'
import offerSubscriber from './offerChildSubscriber'
import offerSubscription from './offerChildSubscription'
import offerTypology from './offerChildTypology'

export default [
  {
    path: '/offers',
    name: 'offers',
    component: () => import('@/views/offer/OffersList.vue'),
    meta: {
      title: 'offer.title',
      pln: 0,
      action: 'OPERATOR_SUBSCRIPTION_VIEW',
      breadcrumb: [
        {
          text: 'action.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offer/:id/:tab/show',
    name: 'offerInformationsShow',
    component: () => import('@/views/offer/OfferManager.vue'),
    meta: {
      title: 'navigation.offer.view',
      action: 'OPERATOR_SUBSCRIPTION_VIEW',
      breadcrumb: [
        {
          text: 'navigation.offers',
          to: '/offers',
        },
        {
          text: 'navigation.offer.view',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
  {
    path: '/offer/:id/:tab/edit',
    name: 'offerInformationsEdit',
    component: () => import('@/views/offer/OfferManager.vue'),
    meta: {
      title: 'navigation.offer.edit',
      action: 'OPERATOR_SUBSCRIPTION_EDIT',
      breadcrumb: [
        {
          text: 'navigation.offers',
          to: '/offers',
        },
        {
          text: 'navigation.offer.edit',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
]
