import EnumSelect from '@/components/EnumSelect.vue'
import AppForm from '@/components/AppForm.vue'
import AppSelect from '@/components/AppSelect.vue'
import AppInput from '@/components/AppInput.vue'
import AppInputPassword from '@/components/AppInputPassword.vue'
import AppDatepicker from '@/components/AppDatepicker.vue'
import AppTextarea from '@/components/AppTextarea.vue'
import AppFormTextInputItn from '@/components/AppFormTextInputItn.vue'

export default {
  install(app) {
    app.component('AppForm', AppForm)
      .component('EnumSelect', EnumSelect)
      .component('AppSelect', AppSelect)
      .component('AppInput', AppInput)
      .component('AppInputPassword', AppInputPassword)
      .component('AppDatepicker', AppDatepicker)
      .component('AppTextarea', AppTextarea)
      .component('AppFormTextInputItn', AppFormTextInputItn)
  },
}
