import VueGoogleMaps from '@fawmi/vue-google-maps'

export default {
  install(app) {
    app.use(VueGoogleMaps, {
      load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        libraries: 'places',
      },
    })
  },
}
