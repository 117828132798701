<template>
  <app-select
    :label-field="label"
    :model-value="modelValue"
    :options="options"
    :label="options[0] && options[0].text ? 'text' : 'value'"
    :reduce="reduce ? reduce : objectFormat ? val => val : val => val.value"
    v-bind="$attrs"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData ? { ...slotData, translatedText: getOptionLabel(slotData) } : {}" />
    </template>

    <template #selected-option="slotData">
      <slot name="selected-option" v-bind="{ ...slotData, translatedText: getOptionLabel(slotData) }">
        {{ getOptionLabel(slotData) }}
      </slot>
    </template>

    <template #option="slotData">
      <slot name="option" v-bind="{ ...slotData, translatedText: getOptionLabel(slotData) }">
        {{ getOptionLabel(slotData) }}
      </slot>
    </template>
  </app-select>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { snakeCase } from 'lodash'

import { useMapActions } from '@/composables/useMap'
import { useGlobalProperties } from '@/composables/useGlobalProperties'

/**
 * For enum no translated on API, create the trad in enum[snakeCase(enumClass)][value]
 *
 * @example
 * <enum-select
 *   id="pricingType"
 *   v-model="product.mainVariant.pricingType"
 *   :label="$t('service.prices.type')"
 *   enum-class="PricingType"
 *   :overlay="!isLoaded"
 *   required
 * />
 *
 * It target the trad in enum.pricing_type[value]
 */

export default {
  name: 'EnumSelect',
}
</script>

<script setup>
const props = defineProps({
  modelValue: {
    type: [String, Object, Array, Number],
    default: '',
  },
  /** The enumClass asked by API */
  enumClass: {
    type: String,
    required: true,
  },
  /** The select label */
  label: {
    type: String,
    default: '',
  },
  /** Override text transformation */
  transformText: {
    type: Function,
    default: null,
  },
  /** Override text transformation */
  text: {
    type: String,
    default: 'value',
  },
  /** If true, return the enum object. By default, return it value only */
  objectFormat: {
    type: Boolean,
    default: false,
  },
  reduce: {
    type: Function,
    default: undefined,
  },
  excludedValues: {
    type: Array,
    default: () => [],
  },
})

defineEmits(['update:model-value'])

const store = useStore()
const { te, t } = useI18n()
const { trans } = useGlobalProperties()
const { fetchEnum2 } = useMapActions('enum', ['fetchEnum2'])

const localOverlay = ref(true)

/**
 * Return the store's enumClass or an empty array
 */
const options = computed(() => {
  const enumClass = store.state.enum[props.enumClass]
  if (enumClass && enumClass.length) {
    if (typeof enumClass[0].value === 'object') {
      return enumClass.map(item => item.value)
    }

    // Pour les chaînes de caractères ou les formats de type FuelType (où la valeur est un nombre)
    const filteredEnumClass = enumClass.filter(item => !props.excludedValues.includes(item.value))

    return filteredEnumClass
  }
  return []
})

/**
 * Render the translation value if available
 */
const getOptionLabel = currentEnum => {
  // TODO: for optimization, if trans is already loaded, return it speedly
  const translation = (text, snakeCaseText = false) => {
    /* There is no translation for this text on API */
    // Override the transformation of the render text
    if (props.transformText) {
      return props.transformText(text)
    }

    // If translation key exists on vue-i18n, return this translation
    if (te(`enum.${snakeCase(props.enumClass)}.${!snakeCaseText ? text : snakeCase(text)}`)) {
      return t(`enum.${snakeCase(props.enumClass)}.${!snakeCaseText ? text : snakeCase(text)}`)
    }
    // Else, return the brut text
    return text
  }

  // Classic enum, without translation api. Automatic translation if no `transformText()` prop
  if (typeof currentEnum[props.text] === 'string') {
    return translation(currentEnum[props.text])
  }
  // Only for 'FuelType' | Format: { value: 3, text: 'Coffee' }
  if (typeof currentEnum[props.text] === 'number') {
    return translation(currentEnum.text, true)
  }
  // Translated enum on API. return `transformText(translatedApiText)` or `translatedApiText`
  if (currentEnum.id) {
    const target = trans(currentEnum.name)
    if (props.transformText) return props.transformText(target)
    return target
  }
  // Default return, but never used in good utilisation
  return ''
}

// Once request even if others <enum-select /> are mounted in same time
if (!store.state.enum[`${props.enumClass}Loading`]) {
  fetchEnum2(props.enumClass).finally(() => {
    localOverlay.value = false
  })
} else {
  localOverlay.value = false
}
</script>

<style lang="scss">
body:not(body.dark-layout) .vs__search::placeholder {
  color: rgba(110, 107, 123, 0.4) !important;
}
</style>
