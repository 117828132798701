export default [
  /* //////////  PROVIDERS ////////// */
  {
    path: '/shop/providers/:trip_id/:step_id/:event_id/:airport_id',
    name: 'provider-list',
    component: () => import('@/views/provider/ProviderList.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      title: 'navigation.trip.shop.providers',
      action: 'TRIP_VIEW',
      breadcrumb() {
        return [
          {
            text: 'navigation.trip.shop.providers',
            active: true,
          },
        ]
      },
      backBtn: true,
    },
  },
  {
    path: '/shop/providers/providerDetails/:provider_id/:trip_id/:step_id/:event_id/:airport_id',
    name: 'provider-details',
    component: () => import('@/views/provider/ProviderDetails.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      title: 'navigation.provider',
      action: 'TRIP_VIEW',
      breadcrumb(route) {
        return [
          {
            text: 'navigation.trip.shop.providers',
            to:
              {
                name: 'provider-list',
                params: {
                  trip_id: route.params.trip_id,
                  step_id: route.params.step_id,
                  event_id: route.params.event_id,
                  airport_id: route.params.airport_id,
                },
              },
          },
          {
            text: 'navigation.provider_profile',
            active: true,
          },
        ]
      },
      backBtn: true,
    },
  },
  {
    path: '/shop/providers/providerDetails/:provider_id/:trip_id/:step_id/:event_id',
    name: 'provider-details-helicopter',
    component: () => import('@/views/provider/ProviderDetails.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      title: 'navigation.provider',
      action: 'TRIP_VIEW',
      breadcrumb() {
        return [
          {
            text: 'navigation.trip.shop.providers',
          },
          {
            text: 'navigation.provider_profile',
          },
        ]
      },
      backBtn: true,
    },
  },
  /* //////////  SERVICE ////////// */
  {
    path: '/shop/service/:provider_id/:trip_id/:step_id/:event_id/:airport_id/:product_id',
    name: 'shop-service-details',
    component: () => import('@/views/shop/shop-details/ShopDetails.vue'),
    meta: {
      pageTitle: 'navigation.product.title',
      action: 'TRIP_VIEW',
      contentClass: 'ecommerce-application',
      breadcrumb(route) {
        return [
          {
            text: 'navigation.trip.shop.providers',
            to:
              {
                name: 'provider-list',
                params: {
                  trip_id: route.params.trip_id,
                  step_id: route.params.step_id,
                  event_id: route.params.event_id,
                  airport_id: route.params.airport_id,
                },
              },
          },
          {
            text: 'navigation.provider_profile',
            to:
              {
                name: 'provider-details',
                params: {
                  provider_id: route.params.provider_id,
                  trip_id: route.params.trip_id,
                  step_id: route.params.step_id,
                  event_id: route.params.event_id,
                  airport_id: route.params.airport_id,
                },
              },
          },
          {
            text: 'navigation.product.title',
            active: true,
          },
        ]
      },
      backBtn: true,
    },
  },
  /* //////////  CHECKOUT ////////// */
  {
    path: '/shop/checkout/:trip_id/:step_id/:event_id/:airport_id',
    name: 'shop-checkout',
    component: () => import('@/views/cart/Cart.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'navigation.trip.shop.checkout',
      action: 'TRIP_VIEW',
      breadcrumb(route) {
        return [
          {
            text: 'navigation.trip.shop.providers',
            to:
              {
                name: 'provider-list',
                params: {
                  trip_id: route.params.trip_id,
                  step_id: route.params.step_id,
                  event_id: route.params.event_id,
                  airport_id: route.params.airport_id,
                },
              },
          },
          {
            text: 'navigation.provider_profile',
            // to:
            //   {
            //     name: 'provider-details',
            //     params: {
            //       provider_id: route.params.provider_id,
            //       trip_id: route.params.trip_id,
            //       step_id: route.params.step_id,
            //       event_id: route.params.event_id,
            //       airport_id: route.params.airport_id,
            //     },
            //   },
          },
          {
            text: 'navigation.trip.shop.checkout',
            // to:
            //   {
            //     name: 'shop-checkout',
            //     params: {
            //       provider_id: route.params.provider_id,
            //       trip_id: route.params.trip_id,
            //       step_id: route.params.step_id,
            //       event_id: route.params.event_id,
            //       airport_id: route.params.airport_id,
            //     },
            //   },
            active: true,
          },
        ]
      },
      backBtn: true,
    },
  },
  /* //////////  HANGAR DETAIL ////////// */
  {
    path: '/shop/providers/hangarDetail/:provider_id/:trip_id/:step_id/:event_id/:product_id/:airport_id',
    name: 'hangar-detail',
    component: () => import('@/views/provider/hangar/HangarDetail.vue'),
    meta: {
      pageTitle: 'navigation.trip.hangar_detail',
      action: 'TRIP_VIEW',
      breadcrumb(route) {
        return [
          {
            text: 'navigation.trip.shop.providers',
            to:
              {
                name: 'provider-list',
                params: {
                  trip_id: route.params.trip_id,
                  step_id: route.params.step_id,
                  event_id: route.params.event_id,
                  airport_id: route.params.airport_id,
                },
                query: {
                  start_at: route.query.start_at,
                  end_at: route.query.end_at,
                },
              },
          },
          {
            text: 'navigation.provider_profile',
            to:
              {
                name: 'provider-details',
                params: {
                  provider_id: route.params.provider_id,
                  trip_id: route.params.trip_id,
                  step_id: route.params.step_id,
                  event_id: route.params.event_id,
                  airport_id: route.params.airport_id,
                },
                query: {
                  start_at: route.query.start_at,
                  end_at: route.query.end_at,
                },
              },
          },
          {
            text: 'navigation.trip.hangar_detail',
            to:
              {
                name: 'hangar-detail',
                params: {
                  product_id: route.params.product_id,
                  provider_id: route.params.provider_id,
                  trip_id: route.params.trip_id,
                  step_id: route.params.step_id,
                  event_id: route.params.event_id,
                  airport_id: route.params.airport_id,
                },
              },
            active: true,
          },
        ]
      },
      backBtn: true,
      backBtnWithQuery: true,
    },
  },
  /* //////////  Mandatory Package ////////// */
  {
    path: '/shop/providers/mandatoryPackage/:provider_id/:trip_id/:step_id/:event_id/:product_id',
    name: 'mandatory-package-detail',
    component: () => import('@/views/provider/mandatoryPackage/MandatoryPackageDetail.vue'),
    meta: {
      pageTitle: 'navigation.trip.mandatory_package_detail',
      action: 'TRIP_VIEW',
      breadcrumb(route) {
        return [
          {
            text: 'navigation.trip.mandatory_package_detail',
            to:
              {
                name: 'mandatory-package-detail',
                params: {
                  product_id: route.params.product_id,
                  provider_id: route.params.provider_id,
                  trip_id: route.params.trip_id,
                  step_id: route.params.step_id,
                  event_id: route.params.event_id,
                  airport_id: route.params.airport_id,
                },
              },
            active: true,
          },
        ]
      },
      backBtn: true,
      backBtnWithQuery: true,
    },
  },
  /*
  //////////  SERVICE HELICOPTER  //////////
  */
  {
    path: '/shop/service/helicopter/trip/:trip_id/step/:step_id/event/:event_id/organization/:required_organization_id/:destination_id/:type',
    name: 'shop-service-helicopter-details',
    component: () => import('@/views/shop/shop-details/ShopHelicopterDetails.vue'),
    meta: {
      pageTitle: 'navigation.product.title',
      action: 'TRIP_VIEW',
      breadcrumb(route) {
        return [
          {
            text: 'navigation.trips',
            to: {
              name: 'trips',
            },
          },
          {
            text: 'navigation.trip.title',
            to: {
              name: 'trip-view',
              params: {
                trip_id: route.params.trip_id,
              },
            },
          },
          {
            text: 'navigation.trip.helicopter',
            to: {
              name: 'TimelineStepTransferHelicopterOrganizations',
              params: {
                trip_id: route.params.trip_id,
                step_id: route.params.step_id,
                event_id: route.params.event_id,
              },
            },
          },
          {
            pln: 0,
            text: 'navigation.product.title',
            active: true,
          },
        ]
      },
      backBtn: true,
    },
  },
  /*
  //////////  VALIDATION DEVIS HELICOPTER  //////////
  */
  {
    path: '/shop/service/helicopter/trip/:trip_id/step/:step_id/event/:event_id/organization/:required_organization_id/validate',
    name: 'helicopter-validation',
    component: () => import('@/views/shop/shop-details/transfer/HelicopterValidation.vue'),
    meta: {
      pageTitle: 'navigation.product.title',
      action: 'TRIP_VIEW',
      breadcrumb(route) {
        return [
          {
            text: 'navigation.trips',
            to: {
              name: 'trips',
            },
          },
          {
            text: 'navigation.trip.title',
            to: {
              name: 'trip-view',
              params: {
                trip_id: route.params.trip_id,
              },
            },
          },
          {
            text: 'navigation.trip.helicopter',
            to: {
              name: 'TimelineStepTransferHelicopterOrganizations',
              params: {
                trip_id: route.params.trip_id,
                step_id: route.params.step_id,
                event_id: route.params.event_id,
              },
            },
          },
          {
            pln: 0,
            text: 'navigation.product.title',
            active: true,
          },
        ]
      },
      backBtn: true,
    },
  },
]
