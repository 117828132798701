import { computed, inject, ref } from 'vue'
import { useForm } from 'vee-validate'
import { pickBy } from 'lodash'
import { useGlobalProperties } from './useGlobalProperties'

export const usePassword = () => {
  const passwordFieldType = ref('password')

  const togglePasswordVisibility = () => {
    passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password'
  }

  const passwordToggleIcon = computed(() => (passwordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))

  return { passwordFieldType, togglePasswordVisibility, passwordToggleIcon }
}

export const useFieldItn = props => {
  const formInstance = inject('$validator')
  const { trans } = useGlobalProperties

  const form = formInstance ? formInstance.value : useForm()

  const firstError = computed(() => {
    const errorsToArray = Object.entries(pickBy(form.errors.value, (_val, key) => key.startsWith(props.formName)))
      .map(key => ({ [key[0]]: key[1] }))
      .filter(error => Object.values(error)[0].length > 0)

    if (errorsToArray.length) {
      const lang = Object.keys(errorsToArray[0]).toString().slice(-2)
      return `${lang.toUpperCase()} : ${Object.values(errorsToArray[0]).map(err => err.replace(`${props.formName}${lang}`, props.label))}`
    }
    return null
  })

  const getRules = lang => {
    const rules = props.required.includes(lang) ? ['required'] : []
    return rules.concat(props.rules).join('|')
  }

  const isRequired = lang => props.required.includes(lang)

  const placeholderLocale = computed(() => {
    let ph = props.label
    if (typeof props.placeholder === 'object') {
      ph = trans(props.placeholder)
    } else if (props.placeholder) {
      ph = props.placeholder
    }
    return ph
  })

  const getFieldName = lang => `${props.formName}-${lang}`

  return {
    ...form,
    firstError,
    getRules,
    isRequired,
    getFieldName,
    placeholderLocale,
  }
}
