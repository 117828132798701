<template>
  <b-v-form-group class="form-group">
    <!-- Load all scoped slots to be used from parent -->
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData || {}" />
    </template>
  </b-v-form-group>
</template>

<script>
export default {
  name: 'BFormGroup',
}
</script>

<script setup>
import { BFormGroup as BVFormGroup } from 'bootstrap-vue-next'

</script>
