import moment from 'moment'
import { snakeCase } from 'lodash'
import { useI18n } from 'vue-i18n'

import store from '@/store/store'
import i18n from '@/libs/i18n'
import DATE from '@/constants/dateFormat'

export default {
  install(app) {
    const numberOrZero = number => (!Number.isNaN(number) && number !== undefined ? number : 0)

    app.config.globalProperties = {
      ...app.config.globalProperties,
      $moment: moment,
      capitalize(value) {
        if (!value) return ''
        return value.toString().charAt(0).toUpperCase() + value.slice(1)
      },
      trans(transObject) {
        if (typeof transObject === 'string') return transObject // ! bad utilization
        if (!transObject || typeof transObject !== 'object') return '' // ! bad utilization
        return transObject[store.state.appConfig.lang] || transObject[store.state.auth.defaultLang] || transObject.en
      },
      enumTranslate(enumValue, enumClass, snakeCaseText = false) {
        if (i18n.global.te(`enum.${snakeCase(enumClass)}.${!snakeCaseText ? enumValue : snakeCase(enumValue)}`)) {
          return i18n.global.t(`enum.${snakeCase(enumClass)}.${!snakeCaseText ? enumValue : snakeCase(enumValue)}`)
        }
        return enumValue
      },
      priceFormat(number, minScale = 2, maxScale = 2, lang = useI18n().locale.value) {
        const nb = numberOrZero(number)
        return new Intl.NumberFormat(lang, {
          style: 'currency',
          currency: store.state.appConfig.currency,
          currencyDisplay: 'symbol',
          minimumFractionDigits: minScale,
          maximumFractionDigits: maxScale,
        }).format(nb)
      },
      percentFormat(number) {
        const { locale } = useI18n()
        const nb = numberOrZero(number)
        return new Intl.NumberFormat(locale.value, {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(nb / 100)
      },
      decimalFormat(number) {
        const { locale } = useI18n()
        const nb = numberOrZero(number)
        return new Intl.NumberFormat(locale.value, {
          currency: store.state.appConfig.currency,
          minimumFractionDigits: 2,
        }).format(nb)
      },
      dateFormat(date, format = 'SMALL_DATE_FORMAT') {
        return moment(date).format(DATE[format])
      },
      dateFormatUtc(date, format = 'SMALL_DATE_FORMAT') {
        return moment.utc(date).format(DATE[format])
      },
      dateFormatLocal(date, utcOffset, format = 'SMALL_DATE_FORMAT') {
        return moment.utc(date).utcOffset(utcOffset).format(DATE[format])
      },
      timeFormatUtc(date, format = 'TIME_FORMAT') {
        return moment.utc(date).format(DATE[format])
      },
      timeFormatLocal(date, utcOffset, format = 'TIME_FORMAT') {
        return moment.utc(date).utcOffset(utcOffset).format(DATE[format])
      },
    }
  },
}
