import { getField, updateField } from 'vuex-map-fields'
import i18n from '@/libs/i18n'
import { fetchOffersRequest } from '@/request/globalApi/requests/offerRequests'

const getDefaultState = () => ({
  offersLoading: false,
  offers: [],
  pagination: { totalItems: 0 },

  paginate: { page: 1, numberOfItemsPerPage: 0 },
  order: {
    referenceOrder: 'asc',
  },

  searchFilterOptions: [
    { value: 'offerReference', text: i18n.global.t('offer.reference') },
  ],
  searchFilterSelection: 'offerReference',
  searchText: null,

  filter: {
    subscriberId: null,
    mainTypologyId: null,
    countryCode: null,
    offerActive: null,
    offerType: null,
  },

  dataTableSort: {
    sortBy: 'offerReference',
    sortDesc: false,
  },

  offersNameLoading: false,
  offersName: [],
})

const defaultState = getDefaultState()

const getters = {
  getField,

  offerById: state => id => {
    const offer = state.offersShowed.find(item => item.id === id)
    return offer
  },

  offerNameActive: state => state.offersName.filter(offer => offer.active === true),

  filtersCount: state => {
    let count = 0
    Object.values(state.filter).forEach(filt => {
      filt && count++
    })
    return count
  },
}

const mutations = {
  updateField,

  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },

  SET_OFFERS_LOADING: (state, loading) => {
    state.offersLoading = loading
  },

  SET_OFFERS: (state, offers) => {
    state.offers = offers
  },

  SET_PAGINATION: (state, pagination) => {
    state.pagination = pagination
  },

  SET_SORT: (state, sort) => {
    state.order = {}
    state.order[`${sort.sortBy}Order`] = sort.sortDesc ? 'desc' : 'asc'
  },

  CLEAR_FILTERS: state => {
    Object.keys(state.filter).forEach(key => {
      state.filter[key] = ''
    })
  },

  SET_DEFAULT_PER_PAGE: (state, perPage) => {
    state.paginate.numberOfItemsPerPage = perPage
  },

  SET_OFFERS_SHOWED: (state, offer) => {
    state.offersShowed.push(offer)
  },

  SET_OFFERS_NAME_LOADING: (state, loading) => {
    state.offersNameLoading = loading
  },

  SET_OFFERS_NAME: (state, offersName) => {
    state.offersName = offersName
  },
}

const actions = {
  resetState({ commit, dispatch }) {
    dispatch('offerCommission/resetState', null, { root: true })
    dispatch('offerInformation/resetState', null, { root: true })
    dispatch('offerSubscriber/resetState', null, { root: true })
    dispatch('offerSubscription/resetState', null, { root: true })
    dispatch('offerTypology/resetState', null, { root: true })
  },
  fetchOffers({ commit, state, dispatch, rootState }, refresh) {
    if (refresh || !state.offers.length) {
      state.paginate.numberOfItemsPerPage === 0
          && commit('SET_DEFAULT_PER_PAGE', rootState.appConfig.dataTable.perPage)
      // TODO refactor
      const search = {}
      search[state.searchFilterSelection] = state.searchText
      commit('SET_OFFERS_LOADING', true)
      fetchOffersRequest({
        ...state.paginate,
        ...state.order,
        ...search,
        ...state.filter,
      })
        .then(response => {
          commit('SET_OFFERS', response.data.offers)
          commit('SET_PAGINATION', {
            totalItems: response.data.totalItems,
          })
          dispatch('clearOffersShowed')
        })
        .finally(() => {
          commit('SET_OFFERS_LOADING', false)
        })
    }
  },

  setSort({ commit, dispatch }, sort) {
    commit('SET_SORT', sort)
    dispatch('fetchOffers', true)
  },

  setPage({ commit, dispatch }, page) {
    commit('updateField', { path: 'paginate.page', value: page })
  },

  clearFilters({ commit, dispatch }) {
    commit('CLEAR_FILTERS')
    dispatch('fetchOffers', true)
  },

  setOffersShowed({ commit }, offer) {
    commit('SET_OFFERS_SHOWED', offer)
  },

  clearOffersShowed({ dispatch }) {
    dispatch('offerInformation/clearOfferInformationsShowed', null, { root: true })
    dispatch('offerTypology/clearOfferTypologiesShowed', null, { root: true })
    dispatch('offerCommission/clearOfferCommissionsShowed', null, { root: true })
    dispatch('offerSubscription/clearOfferSubscriptionsShowed', null, { root: true })
    dispatch('offerSubscriber/clearOfferSubscribersShowed', null, { root: true })
  },

  // AWAIT light version from api
  fetchoffersName({ commit, state }) {
    if (!state.offersName.length) {
      commit('SET_OFFERS_NAME_LOADING', true)
      fetchOffersRequest({ light: true, getAllItems: true })
        .then(response => {
          commit('SET_OFFERS_NAME', response.data.offers)
        })
        .finally(() => {
          commit('SET_OFFERS_NAME_LOADING', false)
        })
    }
  },
}
export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
}
