<template>
  <b-offcanvas
    v-model:model-value="visible"
    body-class="bg-white text-dark"
    v-bind="offcanvasProps"
    @show="$emit('change', true)"
    @hide="$emit('change', false)"
  >
    <!-- Load all scoped slots to be used from parent -->
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData || {}" />
    </template>
  </b-offcanvas>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'BSidebar',
}
</script>

<script setup>
import { BOffcanvas } from 'bootstrap-vue-next'

const visible = defineModel('visible', { type: Boolean, default: false })

const props = defineProps({
  bgVariant: {
    type: String,
    default: 'white',
  },
  teleportTo: {
    type: String,
    default: '#wrapperPopup',
  },
  placement: {
    type: String,
    default: undefined,
  },
  lazy: {
    type: Boolean,
    default: true,
  },
  noHeader: {
    type: Boolean,
    default: true,
  },
  right: {
    type: Boolean,
    default: true,
  },
  shadow: {
    type: Boolean,
    default: true,
  },
})

defineEmits(['change'])

const offcanvasProps = computed(() => {
  const {
    visible, visibleModifiers, bgVariant, right, shadow, sidebarClass, ...rest
  } = props
  if (!rest.placement && right) rest.placement = 'end'

  return rest
})
</script>
