<template>
  <b-form v-if="tag === 'form'" @submit.prevent="$emit('submit', $event)">
    <slot v-bind="formBind" />
  </b-form>
  <slot v-else v-bind="formBind" />
</template>

<script setup>
import { computed, inject, provide } from 'vue'
import { useForm } from 'vee-validate'

const props = defineProps({
  tag: {
    type: String,
    default: 'div',
  },
})

defineEmits(['submit'])

// const truc = inject('$validator')

const { meta, resetForm, ...form } = useForm()

// const dirty = computed(() => meta.value.dirty)
// const pristine = computed(() => !dirty.value)

// const valid = computed(() => meta.value.valid)
// const invalid = computed(() => !valid.value)

const formBind = computed(() => ({
  ...form,
  meta: meta.value,
  // dirty: dirty,
  // pristine: pristine,
  // valid: valid,
  // invalid: invalid,
  resetForm,
  reset: resetForm,
}))

provide('$validator', formBind)

defineExpose(formBind.value)
</script>
