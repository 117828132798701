import Swal from 'sweetalert2'
import { useToast } from 'vue-toastification'
import ToastificationContent from '@/components/ToastificationContent.vue'

export default {
  methods: {
    alert(title, text, icon) {
      Swal.fire({
        title,
        text,
        icon: icon || 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    toaster(title, variant, icon) {
      const toast = useToast()

      toast({
        component: ToastificationContent,
        props: {
          title,
          icon: icon || 'CheckCircleIcon',
          variant: variant || 'danger',
        },
      })
    },
  },
}
