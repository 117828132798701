<template>
  <b-v-overlay
    :rounded="rounded"
    :spinner-small="spinnerSmall"
    :spinner-variant="spinnerVariant"
    :opacity="opacity"
    :no-fade="noFade"
    :variant="variant"
  >
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData || {}" />
    </template>
  </b-v-overlay>
</template>

<script>
export default {
  name: 'BOverlay',
}
</script>

<script setup>
import { BOverlay as BVOverlay } from 'bootstrap-vue-next'

defineProps({
  rounded: {
    type: Boolean,
    default: true,
  },
  spinnerSmall: {
    type: Boolean,
    default: true,
  },
  spinnerVariant: {
    type: String,
    default: 'primary',
  },
  opacity: {
    type: Number,
    default: 0.85,
  },
  noFade: {
    type: Boolean,
    default: true,
  },
  variant: {
    type: String,
    default: 'transparent',
  },
})
</script>
