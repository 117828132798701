<template>
  <div class="b-media"> <!--  d-flex align-items-center -->
    <div class="b-media-aside">
      <slot name="aside"></slot>
    </div>
    <div class="b-media-body">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped>
.b-media {
  display: flex;
  align-items: center;
}

.b-media-aside {
  margin-right: 1rem;
}
</style>
