import { getField, updateField } from 'vuex-map-fields'
import i18n from '@/libs/i18n'
import ability from '@/libs/ability'
import {
  fetchQuoteFiltersRequest,
  fetchQuoteRequest,
  fetchQuotesCalendarRequest,
  fetchQuotesRequest, patchQuoteBillingRequest,
} from '@/request/globalApi/requests/quoteRequests'

const getDefaultState = () => ({
  quotesLoading: false,
  quotesCalendarLoading: false,
  quotes: [],
  quotesCalendar: [],
  currentCalendarRange: {},
  pagination: { totalItems: 0 },

  paginate: { page: 1, numberOfItemsPerPage: 10 },
  order: {
    createdAtOrder: 'desc',
  },
  searchFilterOptions: [
    { value: 'reference', text: i18n.global.t('offer.reference') },
  ],
  search: {
    reference: null,
  },
  filter: {
    createdAtStart: null,
    createdAtEnd: null,
    aircraftsIds: [],
    helicoptersIds: [],
    airportsIds: [],
    productsIds: [],
    statesCodes: [],
    cancelled: null,
    withSepaTerms: null,
    paymentTerms: null,
    movementType: null,
    flightMission: null,
    customersIds: [],
    providersIds: [],
  },
  filtersOptionsLoading: false,
  filtersOptions: {
    providers: [],
    customers: [],
    products: [],
    aircrafts: [],
    helicopters: [],
    airports: [],
  },

  dataTableSort: {
    sortBy: 'createdAt',
    sortDesc: true,
  },
  searchField: 'reference',
  searchText: null,

  quotesShowed: [],

  // AWAIT  API return title for a quote
  quoteLoading: false,
  quoteTitle: '',
  quoteEditable: false,
  quoteTouched: false,
  quoteUpdated: false,
  isQuoteOwner: false,
  isQuoteClient: false,
  quotePaymentTermsRequired: false,
  validateQuote: false,
  quoteValid: false,
  quoteSlotManagement: false,
  quoteSellerAck: false,
  quoteSelected: {
    quote: {
      id: null,
      lines: [],
      paymentTerm: '',
      state: '',
      concretePaymentDate: '',
      cancellationPolicyType: '',
    },
    invoiceConfiguration: null,
    checkout: {},
    paymentIntent: { status: null },
    offerOrCommissionQuote: false,
  },
  dateCalendarSelected: '',
})

const defaultState = getDefaultState()

const getters = {
  getField,

  isQuoteCancelledOrRefused: state => state.quoteSelected.quote.cancelled || state.quoteSelected.quote.refused || state.quoteSelected.quote.cancelledDraft,

  quoteTitleAndId: state => `${state.quoteTitle} #${state.quoteSelected.quote.id}`,

  quoteTitleAndRef: state => `${state.quoteTitle} #${state.quoteSelected.quote.reference}`,

  quoteTitle: state => {
    const quoteState = state.quoteSelected?.quote?.state
    if (quoteState === undefined) {
      return ''
    }

    if (quoteState === 'awaiting_for_quotation') return i18n.global.t('supply_document.type.quotation_request')
    if (quoteState === 'awaiting_customer_validation') return i18n.global.t('supply_document.type.quotation')
    if (quoteState === 'awaiting_for_services_completed' || quoteState === 'delivered' || quoteState === 'claimed' || quoteState === 'cancellation_applied') return i18n.global.t('supply_document.type.order')
  },

  paymentError: state => {
    if (state.quoteSelected?.paymentIntent?.status) {
      return (
        state.quoteSelected.paymentIntent?.status === 'requires_payment_method'
        || state.quoteSelected.paymentIntent?.status === 'requires_action'
      )
    }

    return false
  },

  filtersCount: state => {
    let count = 0
    Object.values(state.filter).forEach(filt => {
      if (Array.isArray(filt) && filt.length > 0) {
        count += 1
      }
      if (!Array.isArray(filt) && filt) {
        count += 1
      }
    })
    return count
  },

  filtersCalendarCount: state => {
    let count = 0
    // Retire les filtres start et end qui ne sont pas dans le calendrier sans les supprimer du state
    const filter = { ...state.filter }
    delete filter.createdAtStart
    delete filter.createdAtEnd
    delete filter.aircraftsIds
    delete filter.helicoptersIds
    delete filter.airportsIds
    delete filter.movementType
    delete filter.flightMission

    Object.values(filter).forEach(filt => {
      if (Array.isArray(filt) && filt.length > 0) {
        count += 1
      }
      if (!Array.isArray(filt) && filt) {
        count += 1
      }
    })
    return count
  },
}

const mutations = {
  updateField,

  RESET_QUOTE_SELECTED: state => {
    state.quoteLoading = getDefaultState().quoteLoading
    state.quoteTitle = getDefaultState().quoteTitle
    state.quoteEditable = getDefaultState().quoteEditable
    state.quoteTouched = getDefaultState().quoteTouched
    state.quoteUpdated = getDefaultState().quoteUpdated
    state.isQuoteOwner = getDefaultState().isQuoteOwner
    state.isQuoteClient = getDefaultState().isQuoteClient
    state.quotePaymentTermsRequired = getDefaultState().quotePaymentTermsRequired
    state.quoteSlotManagement = getDefaultState().quoteSlotManagement
    state.quoteSellerAck = getDefaultState().quoteSellerAck
    state.validateQuote = getDefaultState().validateQuote
    state.quoteValid = getDefaultState().quoteValid
    state.quoteSelected = getDefaultState().quoteSelected
  },

  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },

  SET_QUOTES_LOADING: (state, loading) => {
    state.quotesLoading = loading
  },

  SET_CURRENT_CALENDAR_RANGE: (state, range) => {
    state.currentCalendarRange = range
  },

  SET_QUOTES_CALENDAR_LOADING: (state, loading) => {
    state.quotesCalendarLoading = loading
  },

  SET_QUOTE_LOADING: (state, loading) => {
    state.quoteLoading = loading
  },

  SET_QUOTES: (state, quotes) => {
    state.quotes = quotes
  },

  SET_QUOTES_CALENDAR: (state, quotes) => {
    state.quotesCalendar = quotes
  },

  SET_QUOTE_SELECTED: (state, quote) => {
    state.quoteSelected = quote
  },

  SET_PAGINATION: (state, pagination) => {
    state.pagination = pagination
  },

  SET_SORT: (state, sort) => {
    state.order = {}
    state.order[`${sort.sortBy}Order`] = sort.sortDesc ? 'desc' : 'asc'
  },

  SET_SEARCH: (state, search) => {
    state.searchText = search
    state.searchField === '' && (state.searchField = 'reference')
    Object.keys(state.search).forEach(key => {
      state.search[key] = ''
    })
    state.search[state.searchField] = search
  },

  CLEAR_FILTERS: state => {
    Object.keys(state.filter).forEach(key => {
      if (key === 'createdAtStart' || key === 'createdAtEnd') {
        state.filter[key] = ''
      } else if (key === 'movementType' || key === 'flightMission' || key === 'cancelled' || key === 'withSepaTerms') {
        state.filter[key] = null
      } else {
        state.filter[key] = []
      }
    })
  },

  SET_DEFAULT_PER_PAGE: (state, perPage) => {
    state.paginate.numberOfItemsPerPage = perPage
  },

  SET_QUOTES_SHOWED: (state, quote) => {
    state.quotesShowed.push(quote)
    state.quoteUpdated = false
  },

  UPDATE_QUOTES_SHOWED: (state, quote) => {
    const index = state.quotesShowed.findIndex(item => item.id === quote.id)
    state.quotesShowed[index] = quote
    state.quoteUpdated = true
  },

  CLEAR_QUOTES_SHOWED: state => {
    state.quotesShowed = []
  },

  CLEAR_QUOTE_SELECTED: state => {
    // state.quoteTitle = ''
    // state.quoteEditable = false
    // state.quoteTouched = false
    state.quoteUpdated = false
    // state.isQuoteOwner = false
    state.quoteSelected = {
      quote: {
        lines: [],
        paymentTerm: '',
        concretePaymentDate: '',
      },
      invoiceConfiguration: null,
      checkout: {},
      paymentIntent: {},
    }
  },

  SET_QUOTE_FILTERS_OPTION_LOADING: (state, loading) => {
    state.filtersOptionsLoading = loading
  },

  SET_QUOTE_FILTERS_OPTION: (state, filtersOptions) => {
    state.filtersOptions = filtersOptions
  },

  SET_IS_QUOTE_OWNER: (state, isQuoteOwner) => {
    state.isQuoteOwner = isQuoteOwner
  },

  SET_IS_QUOTE_CLIENT: (state, isQuoteClient) => {
    state.isQuoteClient = isQuoteClient
  },

  SET_QUOTE_EDITABLE: (state, quoteEditable) => {
    state.quoteEditable = quoteEditable
  },

  SET_PAGE: (state, page) => {
    state.paginate.page = page
  },
  SET_QUOTE_SLOT_MANAGEMENT: (state, quoteSlotManagement) => {
    state.quoteSlotManagement = quoteSlotManagement
  },
  SET_QUOTE_SELLER_ACK: (state, quoteSellerAck) => {
    state.quoteSellerAck = quoteSellerAck
  },
}

const actions = {
  defineQuoteEdition({ commit, state }) {
    if (ability.can('TRIP_ACCOUNTING_EDIT') && state.isQuoteOwner && state.quoteSelected.quote.state === 'awaiting_for_quotation' && !state.quoteSelected.quote.refused && !state.quoteSelected.quote.cancelled && !state.quoteSelected.quote.cancelledDraft) {
      commit('SET_QUOTE_EDITABLE', true)
    } else if (ability.can('TRIP_ACCOUNTING_EDIT') && state.isQuoteOwner && state.quoteSelected.quote.state !== 'awaiting_for_quotation' && state.quoteSelected.quote.cancelled && state.quoteSelected.quote.cancellationPoliciesApplied === null) {
      commit('SET_QUOTE_EDITABLE', true)
    } else if (state.quoteEditable && state.isQuoteOwner && state.quoteSelected.quote.state === 'delivered' && state.quoteSelected.quote.invoiceSent === false) {
      commit('SET_QUOTE_EDITABLE', true)
    } else {
      commit('SET_QUOTE_EDITABLE', false)
    }
  },
  resetState({ commit }) {
    commit('RESET_STATE')
  },
  resetQuoteSelected({ commit }) {
    commit('RESET_QUOTE_SELECTED')
  },
  setCurrentCalendarRange({ commit }, range) {
    commit('SET_CURRENT_CALENDAR_RANGE', range)
  },
  fetchQuotesCalendar({ commit, state }) {
    commit('SET_QUOTES_CALENDAR_LOADING', true)
    const startDate = state.currentCalendarRange.start
    const endDate = state.currentCalendarRange.end
    fetchQuotesCalendarRequest({
      ...state.filter,
      start: startDate,
      end: endDate,
    })
      .then(response => {
        commit('SET_QUOTES_CALENDAR', response.data.quotes)
      })
      .finally(() => {
        commit('SET_QUOTES_CALENDAR_LOADING', false)
      })
  },
  fetchQuotes({ commit, state, rootState }, refresh) {
    if (refresh || !state.quotes.length || state.quoteUpdated) {
      commit('SET_QUOTES_LOADING', true)
      state.paginate.numberOfItemsPerPage === 0
      && commit('SET_DEFAULT_PER_PAGE', rootState.appConfig.dataTable.perPage)
      fetchQuotesRequest({
        ...state.paginate,
        ...state.order,
        ...state.search,
        ...state.filter,
      })
        .then(response => {
          commit('SET_QUOTES', response.data.quotes)
          commit('SET_PAGINATION', {
            totalItems: response.data.totalItems,
          })
          commit('CLEAR_QUOTES_SHOWED')
        })
        .finally(() => {
          commit('SET_QUOTES_LOADING', false)
        })
    }
  },

  async fetchQuote({ commit, state, dispatch }, payload) {
    commit('CLEAR_QUOTE_SELECTED')
    let quote = state.quotesShowed.find(item => item.quote.id === payload.id)
    if (quote && !payload.refresh) {
      dispatch('setQuoteSelected', quote)
    } else {
      await fetchQuoteRequest(payload.id).then(response => {
        quote = response.data
        if (quote.quote.clientAddress === null) {
          // Initialisation de l'objet clientAddress
          quote.quote.clientAddress = {
            address: null,
            complementaryAddress: null,
            city: null,
            postalCode: null,
            countryName: null,
          }
        }
        dispatch('setQuoteSelected', quote)
        if (!payload.refresh) commit('SET_QUOTES_SHOWED', quote)
        if (payload.refresh) commit('UPDATE_QUOTES_SHOWED', quote)
        if (quote.quote.state !== 'awaiting_customer_validation' || quote.quote.cancelled === true || quote.quote.cancelledDraft === true || quote.quote.refused === true) {
          dispatch('defineQuoteEdition')
        }
      })
    }
  },

  setQuoteSelected({ commit, rootGetters }, quote) {
    const isQuoteOwner = quote.quote.organization.id
      === rootGetters['auth/selectedOrganizationId']
    const isQuoteClient = quote.quote.client.id
      === rootGetters['auth/selectedOrganizationId']
    commit('SET_QUOTE_SELECTED', quote)
    commit('SET_IS_QUOTE_OWNER', isQuoteOwner)
    commit('SET_IS_QUOTE_CLIENT', isQuoteClient)
    commit('SET_QUOTE_SLOT_MANAGEMENT', quote.quote.slotManagement)
    commit('SET_QUOTE_SELLER_ACK', quote.quote.sellerAck)
  },

  setSort({ commit, dispatch }, sort) {
    commit('SET_SORT', sort)
    dispatch('fetchQuotes', true)
  },

  setSearch({ commit }, search) {
    commit('SET_SEARCH', search)
  },

  clearFilters({ commit, dispatch }, calendarMode) {
    commit('CLEAR_FILTERS')
    calendarMode ? dispatch('fetchQuotesCalendar') : dispatch('fetchQuotes', true)
  },

  clearQuotesShowed({ commit }) {
    commit('CLEAR_QUOTES_SHOWED')
  },

  clearQuoteSelected({ commit }) {
    commit('CLEAR_QUOTE_SELECTED')
  },

  fetchQuoteFiltersOption({ commit, state }) {
    if (!state.filtersOptions.providers.length && !state.filtersOptions.customers.length && !state.filtersOptions.products.length && !state.filtersOptions.aircrafts.length && !state.filtersOptions.helicopters.length && !state.filtersOptions.airports.length) {
      commit('SET_QUOTE_FILTERS_OPTION_LOADING', true)
      fetchQuoteFiltersRequest()
        .then(response => {
          const filters = response.data
          filters.aircrafts = response.data.aircrafts.map(aircraft => {
            let nameInfo = `${aircraft.type} - ${aircraft.callSign}`
            if (aircraft.registration) {
              nameInfo += ` - ${aircraft.registration}`
            }

            return { ...aircraft, nameInfo }
          })
          filters.helicopters = response.data.helicopters.map(helicopter => {
            let nameInfo = `${helicopter.modelType} - ${helicopter.callSign}`
            if (helicopter.registration) {
              nameInfo += ` - ${helicopter.registration}`
            }

            return { ...helicopter, nameInfo }
          })
          commit('SET_QUOTE_FILTERS_OPTION', filters)
        })
        .finally(() => {
          commit('SET_QUOTE_FILTERS_OPTION_LOADING', false)
        })
    }
  },
  updateBilling({ commit, state }, billing) {
    patchQuoteBillingRequest(state.quoteSelected.quote.id, billing)
  },
  toggleEditable({ commit }, editable) {
    commit('SET_QUOTE_EDITABLE', editable)
  },
  setPage({ commit }, page) {
    commit('SET_PAGE', page)
  }
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
}
