import lodash from './lodash'
import getMeasurementUnitItn from './getMeasurementUnitItn'

export default {
  install(app) {
    app.config.globalProperties = {
      ...app.config.globalProperties,
      ...lodash,
      ...getMeasurementUnitItn,
    }
  },
}
