import { h } from 'vue'
import vSelect from 'vue-select'

import FeatherIcon from '@/components/FeatherIcon.vue'

vSelect.props.components.default = () => ({
  Deselect: {
    render: () => h(FeatherIcon, { size: '14', icon: 'XIcon' }),
  },
  OpenIndicator: {
    render: () => h(FeatherIcon, { size: '15', icon: 'ChevronDownIcon', class: 'open-indicator' }),
  },
})

export default vSelect
