/* eslint-disable import/prefer-default-export */
import FlatPickrLocales from 'flatpickr/dist/l10n/fr'
import { useI18n } from 'vue-i18n'

const flatPickrLocales = FlatPickrLocales
Object.keys(flatPickrLocales).forEach(key => {
  flatPickrLocales[key].firstDayOfWeek = 1
})

export const useFlatpickr = () => {
  const { locale } = useI18n()
  const config = {
    locale: flatPickrLocales[locale.value],

    // Permet d'avoir la date affichée en UTC
    onReady: (selectedDates, dateStr, instance) => {
      if (selectedDates[0]) {
        instance.setDate(new Date(selectedDates[0].toISOString()), false)
      }
    },
  }

  return { config }
}
