<template>
  <span v-bind="filteredAttrs" :class="badgeClasses">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'BBadge',
}
</script>

<script setup>
import { computed, toRefs, useAttrs } from 'vue'

const props = defineProps({
  variant: {
    type: String,
    default: 'primary',
  },
  pill: {
    type: Boolean,
    default: false,
  },
})

const attrs = useAttrs()

const { variant, pill } = toRefs(props)

const badgeClasses = computed(() => {
  const classes = ['badge', `bg-${variant.value}`]
  if (pill.value) {
    classes.push('rounded-pill')
  }
  return classes
})

// Filtrer `attrs` pour ne pas inclure les clés qui sont des props définis
const filteredAttrs = computed(() => {
  const { class: _, ...otherAttrs } = attrs
  return otherAttrs
})
</script>
