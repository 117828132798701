import store from '@/store/store'
import i18n from '@/libs/i18n'

import MEASUREMENT from '@/constants/measurement'

/**
 * @param {String} unit Unit to set. Must be a system metric abbreviation (m, l, kg,...)
 * @param {Number} length If provided, return the plurialized translated long name (meters, ...).
 * @returns {String}
 */
const getMeasurementUnitItn = (unit, length = null) => {
  const system = store.state.appConfig.measurement || 'metric'

  // ?: default value = null? undefined or empty string?
  // ! Bad use case, return `empty string` as default value.
  // ! (1) The system measurement in store (and globalApi header) is not accepted)
  // ! (2) The unit measurement asked is not accepted).
  if (!MEASUREMENT[system] || !MEASUREMENT[system][unit]) return ''

  const format = length === null ? 'abbr' : 'long'

  const result = MEASUREMENT[system][unit][format]

  // No translation for abbr
  if (length === null) return result

  // Translation if it exists
  if (i18n.global.te(`measurement.unit.${result}`)) return i18n.global.t(`measurement.unit.${result}`, length)

  // Else, return the long name
  return result
}

export default { $getMeasurementUnitItn: getMeasurementUnitItn }
