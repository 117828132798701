import { getField, updateField } from 'vuex-map-fields'
import { fetchCrewMembersRequest } from '@/request/globalApi/requests/crewMemberRequests'

export default {
  namespaced: true,
  state: {
    crewMembers: [],
    crewMembersLoading: false,
  },
  getters: {
    getField,
    crewMembers: state => state.crewMembers,
    crewMembersLoading: state => state.crewMembersLoading,
  },
  mutations: {
    updateField,
    SET_CREW_MEMBERS(state, crewMembers) {
      state.crewMembers = crewMembers
    },
    SET_CREW_MEMBERS_LOADING(state, crewMembersLoading) {
      state.crewMembersLoading = crewMembersLoading
    },
  },
  actions: {
    async fetchCrewMembers({ state, commit }, force) {
      commit('SET_CREW_MEMBERS_LOADING', true)
      return fetchCrewMembersRequest()
        .then(response => {
          const { crewMembers } = response.data
          commit('SET_CREW_MEMBERS', crewMembers)
        })
        .finally(() => {
          commit('SET_CREW_MEMBERS_LOADING', false)
        })
    },
  },
}
