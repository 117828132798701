/* eslint-disable import/prefer-default-export */
import { ref } from 'vue'

const collapses = ref({})
const lastCollapsed = ref()

export const useCollapse = () => {
  const setCollapse = collapseName => {
    collapses.value[collapseName] = !collapses.value[collapseName]
    lastCollapsed.value = collapseName
  }

  const clearAllCollapse = () => {
    collapses.value = {}
  }

  const clearByPrefix = (prefix) => {
    Object.keys(collapses.value).forEach((key) => {
      if (key.startsWith(prefix)) {
        delete collapses.value[key]
      }
    })
  }

  return {
    collapses,
    lastCollapsed,
    setCollapse,
    clearAllCollapse,
    clearByPrefix,
  }
}
