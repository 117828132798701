import {
  BFormFile,
  BTableSimple,
  BCollapse,
  BSpinner,
  BPagination,
  BTd,
  BTh,
  BTr,
  BThead,
  BTbody,
  BTfoot,
  BNav,
  BNavbar,
  BNavbarNav,
  BNavbarToggle,
  BNavItem,
  BNavItemDropdown,
  BContainer,
  BRow,
  BCol,
  BTabs,
  BTab,
  BLink,
  BForm,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadio,
  BFormRadioGroup,
  BFormSelect,
  BFormSelectOption,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BCardBody,
  BCardTitle,
  BCardHeader,
  BCardText,
  BCardFooter,
  BCardImg,
  BDropdown,
  BDropdownDivider,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue-next'

import BButton from '@/BoostrapConfiguration/BButton.vue'
import BButtonGroup from '@/BoostrapConfiguration/BButtonGroup.vue'
import BSidebar from '@/BoostrapConfiguration/BSidebar.vue'
import BOverlay from '@/BoostrapConfiguration/BOverlay.vue'
import BTable from '@/BoostrapConfiguration/BTable.vue'
import BBadge from '@/BoostrapConfiguration/BBadge.vue'
import BImg from '@/BoostrapConfiguration/BImg.vue'
import BAvatar from '@/BoostrapConfiguration/BAvatar.vue'
import BAlert from '@/BoostrapConfiguration/BAlert.vue'
import BInputGroup from '@/BoostrapConfiguration/BInputGroup.vue'
import BInputGroupPrepend from '@/BoostrapConfiguration/BInputGroupPrepend.vue'
import BInputGroupAppend from '@/BoostrapConfiguration/BInputGroupAppend.vue'
import BDropdownItem from '@/BoostrapConfiguration/BDropdownItem.vue'
import BMedia from '@/BoostrapConfiguration/BMedia.vue'
import BFormGroup from '@/BoostrapConfiguration/BFormGroup.vue'
import BCardSubTitle from '@/BoostrapConfiguration/BCardSubTitle.vue'
import BModal from '@/BoostrapConfiguration/BModal.vue'
import BTooltip from '@/BoostrapConfiguration/BTooltip.vue'

export default {
  install(app) {
    app.component('BContainer', BContainer)
      .component('BSidebar', BSidebar)
      .component('BOverlay', BOverlay)
      .component('BTable', BTable)
      .component('BTableSimple', BTableSimple)
      .component('BButton', BButton)
      .component('BRow', BRow)
      .component('BCol', BCol)
      .component('BTabs', BTabs)
      .component('BNavbar', BNavbar)
      .component('BLink', BLink)
      .component('BBadge', BBadge)
      .component('BFormInput', BFormInput)
      .component('BFormGroup', BFormGroup)
      .component('BTab', BTab)
      .component('BCollapse', BCollapse)
      .component('BFormTextarea', BFormTextarea)
      .component('BImg', BImg)
      .component('BNavItemDropdown', BNavItemDropdown)
      .component('BAvatar', BAvatar)
      .component('BDropdownItem', BDropdownItem)
      .component('BNavbarNav', BNavbarNav)
      .component('BNavItem', BNavItem)
      .component('BDropdownDivider', BDropdownDivider)
      .component('BFormCheckbox', BFormCheckbox)
      .component('BFormFile', BFormFile)
      .component('BBreadcrumb', BBreadcrumb)
      .component('BBreadcrumbItem', BBreadcrumbItem)
      .component('BCard', BCard)
      .component('BCardBody', BCardBody)
      .component('BCardHeader', BCardHeader)
      .component('BCardText', BCardText)
      .component('BListGroup', BListGroup)
      .component('BListGroupItem', BListGroupItem)
      .component('BTooltip', BTooltip)
      .component('BMedia', BMedia)
      .component('BFormSelect', BFormSelect)
      .component('BSpinner', BSpinner)
      .component('BInputGroup', BInputGroup)
      .component('BInputGroupPrepend', BInputGroupPrepend)
      .component('BDropdown', BDropdown)
      .component('BInputGroupAppend', BInputGroupAppend)
      .component('BPagination', BPagination)
      .component('BForm', BForm)
      .component('BCardTitle', BCardTitle)
      .component('BFormCheckboxGroup', BFormCheckboxGroup)
      .component('BFormSelectOption', BFormSelectOption)
      .component('BModal', BModal)
      .component('BAlert', BAlert)
      .component('BCardSubTitle', BCardSubTitle)
      .component('BFormRadio', BFormRadio)
      .component('BFormRadioGroup', BFormRadioGroup)
      .component('BNav', BNav)
      .component('BTh', BTh)
      .component('BTd', BTd)
      .component('BButtonGroup', BButtonGroup)
      .component('BCardImg', BCardImg)
      .component('BCardFooter', BCardFooter)
      .component('BNavbarToggle', BNavbarToggle)
      .component('BTr', BTr)
      .component('BThead', BThead)
      .component('BTbody', BTbody)
      .component('BTfoot', BTfoot)
  },
}
