// TODO bad routing use heritage

export default [
  {
    path: '/trips',
    name: 'trips',
    component: () => import('@/views/trip/TripList.vue'),
    meta: {
      title: 'navigation.trips',
      action: 'TRIP_VIEW',
      breadcrumb: [
        {
          text: 'navigation.trips',
          active: true,
        },
      ],
    },
  },
  {
    path: '/operator/trips',
    name: 'operator_trips',
    component: () => import('@/views/trip/TripList.vue'),
    meta: {
      title: 'navigation.trips',
      action: 'OPERATOR_TRIP_VIEW',
      breadcrumb: [
        {
          text: 'navigation.trips',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trips/:trip_id',
    name: 'trip-details',
    component: () => import('@/views/trip/TripDetail.vue'),
    meta: {
      title: 'navigation.trip.title',
      action: 'TRIP_VIEW',
      breadcrumb: [
        {
          text: 'navigation.trips',
          to: '/trips',
        },
        {
          text: 'navigation.trip.title',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
  {
    path: '/trip-view/:trip_id',
    name: 'trip-view',
    component: () => import('@/views/trip/TripDetail.vue'),
    meta: {
      title: 'navigation.trip.title',
      action: 'TRIP_VIEW',
      breadcrumb: [
        {
          text: 'navigation.trips',
          to: '/trips',
        },
        {
          text: 'navigation.trip.title',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
  {
    path: '/trip-edit',
    name: 'trip-edit',
    // Le composant est utilisé pour l'ajout
    component: () => import('@/views/trip/trip-edit/TripEdit.vue'),
    meta: {
      title: 'navigation.trips',
      action: 'TRIP_ADD',
      breadcrumb: [
        {
          text: 'navigation.trips',
          to: '/trips',
        },
        {
          text: 'navigation.trip.edit',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
  {
    path: '/trip-edit/flight/:trip_id',
    name: 'trip-edit-flight',
    component: () => import('@/views/trip/trip-edit/TripEditFlight.vue'),
    meta: {
      title: 'navigation.trips',
      action: 'TRIP_EDIT',
    },
    backBtn: true,
  },
  {
    path: '/trip-edit/aircraft/:trip_id',
    name: 'trip-edit-aircraft',
    component: () => import('@/views/trip/trip-edit/TripEditAircraft.vue'),
    meta: {
      title: 'navigation.trips',
      action: 'TRIP_EDIT',
    },
  },
  /*
  //////////  AIRPORT //////////
  */
  {
    path: '/airport/:trip_id/:event_id/:leg_id',
    name: 'airport-map',
    component: () => import('@/views/airport/AirportMap.vue'),
    meta: {
      title: 'navigation.trip.airport',
      action: 'TRIP_EDIT',
      breadcrumb(route) {
        return [
          {
            text: 'navigation.trips',
            to: '/trips',
          },
          {
            text: 'navigation.trip.title',
            to:
              {
                name: 'trip-view',
                params: { trip_id: route.params.trip_id },
              },
          },
          {
            text: 'navigation.trip.airport',
            active: true,
          },
        ]
      },
      backBtn: true,
    },
  },
  /*
  //////////  FUEL //////////
  */
  {
    path: '/fuel/:trip_id/:event_id',
    name: 'fuel',
    component: () => import('@/views/provider/fuel/Fuel.vue'),
    meta: {
      title: 'navigation.trip.fuel',
      action: 'TRIP_VIEW',
      breadcrumb(route) {
        return [
          {
            text: 'navigation.trips',
            to: { name: 'trips' },
          },
          {
            text: 'navigation.trip.title',
            to: {
              name: 'trip-view',
              params: { trip_id: route.params.trip_id },
            },
          },
          {
            text: 'navigation.trip.fuel',
            active: true,
          },
        ]
      },
      backBtn: true,
    },
  },
  /*
  //////////  HANGAR //////////
  */
  {
    path: '/hangar/:trip_id/:event_id',
    name: 'hangar',
    component: () => import('@/views/provider/hangar/Hangar.vue'),
    meta: {
      title: 'navigation.trip.hangar',
      action: 'TRIP_VIEW',
      breadcrumb(route) {
        return [
          {
            text: 'navigation.trips',
            to: {
              name: 'trips',
            },
          },
          {
            text: 'navigation.trip.title',
            to: {
              name: 'trip-view',
              params: {
                trip_id: route.params.trip_id,
              },
            },
          },
          {
            text: 'navigation.trip.hangar',
            active: true,
          },
        ]
      },
      backBtn: true,
    },
  },
  /*
  //////////  SLOT //////////
  */
  {
    path: '/submit-slot/:trip_id/:event_id/:leg_id',
    name: 'submit-slot',
    component: () => import('@/views/airport/AirportSubmitSlot.vue'),
    meta: {
      title: 'navigation.trip.submit_slot',
      action: 'TRIP_SLOT_MANAGEMENT_VIEW',
      breadcrumb(route) {
        return [
          {
            text: 'navigation.trips',
            to: '/trips/trips-list',
          },
          {
            text: 'navigation.trip.title',
            to:
              {
                name: 'trip-view',
                params: { trip_id: route.params.trip_id },
              },
          },
          {
            text: 'navigation.trip.submit_slot',
            active: true,
          },
        ]
      },
      backBtn: true,
    },
  },
  /*
  //////////  HELICOPTERS //////////
  */
  {
    path: '/trip/:trip_id/step/:step_id/event/:event_id/helicopters',
    name: 'TimelineStepTransferHelicopterOrganizations',
    component: () => import('@/views/timeline/step/TimelineStepTransferHelicopterOrganizations.vue'),
    meta: {
      action: 'TRIP_EDIT',
      breadcrumb(route) {
        return [
          {
            text: 'navigation.trips',
            to: {
              name: 'trips',
            },
          },
          {
            text: 'navigation.trip.title',
            to: {
              name: 'trip-view',
              params: {
                trip_id: route.params.trip_id,
              },
            },
          },
          {
            pln: 0,
            text: 'navigation.trip.helicopter',
            active: true,
          },
        ]
      },
      backBtn: true,
    },
  },
]
