<template>
  <b-modal id="popConfirmModal" v-bind="params.props()" @hidden="reset">
    {{ params.content }}
    <app-form ref="formRef" tag="form">
      <div v-if="paramsConfirm.options.input">
        {{ $t('alert.delete_confirmation_permanently.validate_deletion') }}
        <app-input id="confirmDelete" v-model="confirDelete" class-group="mt-2" :rules="computedRules" hide-errors>
          <template #error="{ error }">
            <small v-if="error" class="text-danger">{{ $t('alert.delete_confirmation_permanently.validate_deletion') }}</small>
          </template>
        </app-input>
      </div>
    </app-form>
  </b-modal>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useBVModal from '@/composables/useBVModal'
import { useGlobalProperties } from '@/composables/useGlobalProperties'

defineOptions({
  name: 'MsgBoxConfirm',
})

const { t, locale } = useI18n()
const { paramsConfirm, reset } = useBVModal
const { _cloneDeep } = useGlobalProperties()

const defaultProps = {
  title: t('alert.delete_confirmation.title'),
  size: 'sm',
  okVariant: 'primary',
  okTitle: t('common.yes'),
  cancelTitle: t('common.no'),
  cancelVariant: 'outline-secondary',
  hideHeaderClose: false,
  centered: true,
  modelValue: false,
}

const formRef = ref(null)
const confirDelete = ref('')

const params = computed(() => ({
  content: paramsConfirm.value.content,
  props: () => {
    if (!paramsConfirm.value.options.input) {
      return Object.assign(_cloneDeep(defaultProps), paramsConfirm.value.props)
    }

    const { onOk, ...restProps } = paramsConfirm.value.props
    return {
      ..._cloneDeep(defaultProps),
      ...restProps,
      onOk: evt => {
        evt.preventDefault()
        formRef.value.validate().then(({ valid }) => {
          if (valid) {
            reset()
            onOk()
          }
        })
      },
    }
  },
}))

// Génère la règle de suppression en fonction de l'anglais ou du français
// Anglais : DELETE
// Français : SUPPRIMER
const computedRules = computed(() => {
  return locale.value === 'en' ? 'required|is:DELETE' : 'required|is:SUPPRIMER'
})

</script>
