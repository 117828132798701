<template>
  <div :class="['input-group', sizeClass]">
    <slot></slot>
    <template v-if="append">
      <div class="input-group-append">
        <span class="input-group-text">{{ append }}</span>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'

// Define props using the Composition API
const props = defineProps({
  append: String,
  size: {
    type: String,
    default: '',
    validator: (value) => ['sm', 'lg', ''].includes(value),
  },
});

// Computed property to generate the size class
const sizeClass = computed(() => {
  return props.size ? `input-group-${props.size}` : '';
});
</script>
