import { useToast } from 'vue-toastification'
import Swal from 'sweetalert2'
import ToastificationContent from '@/components/ToastificationContent.vue'

export const useToaster = () => {
  const toast = useToast()
  const toaster = (title, variant = 'danger', icon = 'CheckCircleIcon', text) => toast({
    component: ToastificationContent,
    props: { title, text, icon, variant },
  },
  {
    icon: false,
    closeButton: false,
  })

  return { toaster }
}

export const useAlert = () => {
  const alert = (title, text, icon) => {
    Swal.fire({
      title,
      text,
      icon: icon || 'success',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    })
  }

  return { alert }
}
