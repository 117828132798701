<template>
  <b-v-dropdown-item :href="computedHref" :link-class="[linkClass, 'w-100']">
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData || {}" />
    </template>
  </b-v-dropdown-item>
</template>

<script>
export default {
  name: 'BDropdownItem',
}
</script>

<script setup>
import { BDropdownItem as BVDropdownItem } from 'bootstrap-vue-next'
import { useRouter } from 'vue-router'
import { computed } from 'vue'

const router = useRouter()

const props = defineProps({
  variant: {
    type: String,
    default: 'primary',
  },
  to: {
    type: [String, Object],
    default: null,
  },
  linkClass: {
    type: String,
    default: '',
  },
})

// Generate href with vue-router in case of "to" props, otherwise use "href" props
const computedHref = computed(() => {
  if (props.to) {
    return router.resolve(props.to).href
  }
  return undefined
})
</script>
