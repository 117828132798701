import { createStore, createLogger } from 'vuex'

const debug = process.env.NODE_ENV !== 'production'

// Charger dynamiquement tous les modules de store situés dans le dossier `modules`
const modulesFiles = require.context('./modules', false, /\.js$/);

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // Extrait le nom du fichier comme clé du module
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  try {
    const value = modulesFiles(modulePath);
    modules[moduleName] = value.default;
  } catch (error) {
    console.error('Erreur lors de l\'importation du module:', modulePath, error);
  }
  return modules;
}, {});

const actions = {
  resetAll({ dispatch }) {
    dispatch('quote/resetState', null, { root: true })
    dispatch('offer/resetState', null, { root: true })
    dispatch('trip/clearFilters', null, { root: true })
    dispatch('enum/resetState', null, { root: true })
  },
}

const store = createStore({
  modules,
  plugins: debug ? [createLogger()] : [],
  strict: debug,
  actions,
})

export default store
