<template>
  <img :src="src" :alt="alt" :class="imgClasses" @error="$emit('error', $event)" />
</template>

<script setup>
import { computed } from 'vue'

// Define the props with types
const props = defineProps({
  src: {
    type: String,
    default: '',
  },
  alt: {
    type: String,
    default: '',
  },
  fluid: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
})

// Define emits
defineEmits(['error'])

// Computed property to generate the class list
const imgClasses = computed(() => ({
  'img-fluid': props.fluid,
  rounded: props.rounded,
}))

</script>
