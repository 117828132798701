<template>
  <b-v-modal v-bind="props" v-model:model-value="modelValue">
    <template #header-close>×</template>
    <slot />

    <!-- Load all scoped slots to be used from parent -->
    <template v-for="(_, name) in omit($slots, 'header-close')" #[name]="slotData">
      <slot :name="name" v-bind="slotData || {}" />
    </template>
  </b-v-modal>
</template>

<script setup>
import { omit } from 'lodash'
import { BModal as BVModal } from 'bootstrap-vue-next'

defineOptions({
  name: 'BModalExtended',
})

const modelValue = defineModel('modelValue', { type: Boolean, default: false })

const props = defineProps({
  teleportTo: { type: String, default: '#wrapperPopup' },
  headerCloseClass: { type: String, default: 'close' },
})
</script>
