import store from '@/store/store'
import RouterHelper from '@/helpers/routerHelper'
import { createRouter, createWebHistory } from 'vue-router'
import { useCollapse } from '@/composables/useCollapse'

const context = require.context('./routes', false, /^(?!.*Child).*\.js$/)
const routes = []
context.keys().forEach(file => {
  const route = context(file).default || context(file)
  routes.push(...route)
})

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

router.onError(error => {
  if (/Loading chunk chunk-\d* failed./i.test(error.message)) {
    window.location.reload()
  }
})
router.beforeEach((to, _from, next) => {
  // TODO
  // If we force to clear local storage and session storage, and refresh, the url is:
  // http://localhost:8890/login?redirect=%5Bobject%20Object%5D, and keep the login page after the login, who successed. How fix it?
  if (store.state.auth.isAuthorized) {
    if (RouterHelper.canNavigate(to)) next()
    else next({ name: 'not-authorized', query: { redirect: to.path } })
  } else if (RouterHelper.isPublicRoute(to.name)) {
    next()
  } else {
    next({ name: 'login', query: { redirect: to.path } })
  }
})

router.afterEach(() => {
  const { clearAllCollapse } = useCollapse()
  clearAllCollapse()
})

export default router
