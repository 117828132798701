<template>
  <component
    :is="label || classGroup ? 'b-form-group' : 'div'"
    :class="[{ 'validation-required': isRequired }, classGroup]"
    :content-cols-lg="contentColsLg"
    :label-size="labelSize"
    :label="label || undefined"
    :label-for="label ? id : undefined"
    :label-cols="labelCols"
    :label-class="labelClass"
    :label-cols-lg="labelColsLg"
    :label-cols-md="labelColsMd"
    :label-cols-sm="labelColsSm"
    :label-cols-xl="labelColsXl"
  >
    <b-overlay :show="overlay" :variant="$store.state.appConfig.layout.skin === 'dark' ? 'transparent' : undefined">
      <b-input-group :append="append" :class="classInputGroup" :style="styleGroup" :size="size">
        <b-form-input
          :id="id"
          :ref="`AppInput-${id}`"
          v-bind="$attrs"
          :title="localTitle"
          :class="[classInput]"
          :style="styleInput"
          :size="size"
          :state="errors[0] ? false : state"
          :placeholder="placeholder !== false ? placeholder || label : undefined"
          :required="isRequired"
          :model-value="value"
          @update:model-value="textChange($event)"
          @focus="$emit('focus')"
          @blur="forceValidate"
        />
        <slot />
      </b-input-group>
    </b-overlay>
    <small v-if="!hideErrors && errors[0]" class="text-danger">
      {{ deleteFieldText(errors[0]) }}
    </small>
    <slot name="error" :error="errors[0] && deleteFieldText(errors[0])" />
  </component>
</template>

<script setup>
import {computed, watch} from 'vue'
import { useField } from 'vee-validate'
import { useI18n } from 'vue-i18n'

// https://vee-validate.logaretm.com/v3/api/Field.html#scoped-slot-props
// TODO: Mixin for field form components

/**
 * @example
 * <app-input
    id="hookUpFee"
    v-model.number="editedAirport.hookUpFee"
    :label="$t('service.fuel.prices.hook_up_fees')"
    type="number"
    step="0.01"
    rules="positive"
    append="%"
    :overlay="!isLoaded"
  />
 */

defineOptions({
  name: 'AppInput',
  inheritAttrs: false,
})

const props = defineProps({
  modelValue: { type: [Number, String], default: undefined },
  id: { type: String, required: true },
  // eslint-disable-next-line vue/require-prop-types
  append: { default: undefined },
  classGroup: { type: String, default: undefined },
  styleGroup: { type: Object, default: () => ({}) },
  classInputGroup: { type: String, default: undefined },
  classInput: { type: String, default: undefined },
  styleInput: { type: Object, default: () => ({}) },
  vvName: { type: [Boolean, String], default: false },
  label: { type: String, default: '' },
  title: { type: [Boolean, String], default: null },
  size: { type: String, default: undefined },
  contentColsLg: { type: String, default: undefined },
  labelSize: { type: String, default: undefined },
  labelClass: { type: String, default: undefined },
  labelCols: { type: [String, Number], default: undefined },
  labelColsLg: { type: [String, Number], default: undefined },
  labelColsMd: { type: [String, Number], default: undefined },
  labelColsSm: { type: [String, Number], default: undefined },
  labelColsXl: { type: [String, Number], default: undefined },
  overlay: { type: Boolean, default: false },
  placeholder: { type: String, default: undefined },
  rules: { type: String, default: '' },
  required: { type: Boolean, default: false },
  hideErrors: { type: Boolean, default: false },
  state: { type: Boolean, default: null },
  forceValidate: { type: Boolean, default: false },
})

const { locale } = useI18n()

defineEmits(['update:modelValue', 'focus'])

const isRequired = computed(() => props.rules.includes('required') || props.required)

const computedRules = computed(() => {
  let normalizeRules = props.rules
  if (isRequired.value && !normalizeRules.includes('required')) {
    if (!normalizeRules) normalizeRules = 'required'
    else normalizeRules += '|required'
  }
  return normalizeRules
})

const { errors, value, validate } = useField(() => props.id, computedRules, {
  syncVModel: true,
})

const localTitle = computed(() => {
  if (typeof props.title === 'string') return props.title
  if (props.title && value.value) return props.placeholder
  return ''
})

const deleteFieldText = msg => {
  const reg = new RegExp(props.id, 'i')
  if (!props.vvName) return msg.replace(reg, '')
  if (props.vvName === true) return msg.replace(reg, props.label || '')
  return msg.replace(reg, props.vvName)
}

const textChange = val => {
  let inputValue = val
  if (value.value !== inputValue) {
    if (inputValue === '' || Number.isNaN(inputValue)) inputValue = null
  }
  value.value = inputValue
}

const forceValidate = () => {
  if (props.forceValidate) validate()
}

watch(locale, newLocale => {
  if (newLocale) {
    validate()
  }
})
</script>
