import Toast from 'vue-toastification'

// eslint-disable-next-line import/no-unresolved, import/no-absolute-path
import '/src/assets/scss/vue/libs/toastification.scss'

export default {
  install(app) {
    app.use(Toast, {
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })
  },
}
