<template>
  <Field
    v-slot="{ field, errors }"
    :model-value="modelValue"
    :name="id || ''"
    :rules="computedRules"
    @update:model-value="textChange($event)"
  >
    <component
      :is="label ? 'b-form-group' : 'div'"
      :class="[{ 'validation-required': isRequired }, classGroup]"
      :label="label || undefined"
      :label-for="label ? id : undefined"
    >
      <b-overlay :show="overlay" :variant="$store.state.appConfig.layout.skin === 'dark' ? 'transparent' : undefined">
        <b-form-textarea
          :id="id"
          :state="errors[0] ? false : state"
          :placeholder="placeholder !== false ? placeholder || label : undefined"
          :required="isRequired"
          v-bind="{...$attrs, ...field}"
          :model-value="modelValue"
          @focus="$emit('focus')"
          @update:model-value="field.onChange($event)"
        />
      </b-overlay>
      <small v-if="errors[0]" class="text-danger">
        {{ deleteFieldText(errors[0]) }}
      </small>
    </component>
  </Field>
</template>

<script>
import { computed } from 'vue'

/**
 * @example
 * <app-textarea
    id="message"
    v-model="contact.message"
    :label="$t('common.message')"
    rows="5"
  />
 */

export default {
  name: 'AppTextarea',
  inheritAttrs: false,
}
</script>

<script setup>

const emit = defineEmits(['update:model-value', 'focus'])

const props = defineProps({
  modelValue: { type: [Number, String], default: '' },
  id: { type: String, required: true },
  classGroup: { type: String, default: undefined },
  styleGroup: { type: Object, default: () => ({}) },
  vvName: { type: [Boolean, String], default: false },
  label: { type: String, default: '' },
  overlay: { type: Boolean, default: false },
  placeholder: { type: String, default: undefined },
  rules: { type: String, default: '' },
  required: { type: Boolean, default: false },
  state: { type: Boolean, default: null },
})

const isRequired = computed(() => props.rules.includes('required') || props.required)

const computedRules = computed(() => {
  let normalizeRules = props.rules
  if (isRequired.value && !normalizeRules.includes('required')) {
    if (!normalizeRules) normalizeRules = 'required'
    else normalizeRules += '|required'
  }
  return normalizeRules
})

const deleteFieldText = msg => {
  const reg = new RegExp(`${props.id}`, 'i')
  if (!props.vvName) return msg.replace(reg, '')
  if (props.vvName === true) return msg.replace(reg, props.label || '')
  return msg.replace(reg, props.vvName)
}

const textChange = val => {
  emit('update:model-value', val !== '' ? val : null)
}
</script>
