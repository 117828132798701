<template>
  <div :id="`${formName}-text-input-itn`">
    <b-overlay :show="loading">
      <b-tabs :id="`${formName}-text-input-itn-tabs`" align="left">
        <template v-if="showLabel" #tabs-start>
          <li v-if="label" class="tabs-start nav-item align-self-end mr-2" role="presentation">
            <span class="label">{{ label }}</span>
          </li>
        </template>
        <b-tab
          v-for="lang in languages"
          :id="`${formName}-text-input-itn-tab-${lang.locale}`"
          :key="lang.locale"
          :active="lang.locale === localDefaultLang"
          class="tab"
          @click="focusOn(`${formName}-text-input-itn-input-${lang.locale}`)"
        >
          <template #title>
            <div class="title">
              <b-img :src="lang.img" class="img" />
              <span>{{ lang.locale }} </span>
              <span v-if="isRequired(lang.locale)" class="text-danger">&nbsp;* </span>
            </div>
          </template>

          <Field
            v-slot="{ field }"
            :name="getFieldName(lang.locale)"
            :model-value="modelValue ? modelValue[lang.locale] : ''"
            :rules="getRules(lang.locale)"
          >
            <b-form-group>
              <b-form-input
                :id="`${formName}-text-input-itn-input-${lang.locale}`"
                :ref="`${formName}-text-input-itn-input-${lang.locale}`"
                v-bind="omit(field, 'value')"
                :disabled="disabled"
                :placeholder="placeholderLocale"
                :readonly="readonly"
                :state="firstError && false"
                :model-value="modelValue ? modelValue[lang.locale] : ''"
                class="form-input"
                type="text"
                @update:model-value="event => { field.onChange(event); inputText(event, lang.locale) }"
              />
              <small v-if="firstError && !toolTipErrors" class="text-danger">
                {{ deleteFieldText(firstError) }}
              </small>
              <b-tooltip
                v-if="firstError && toolTipErrors"
                :target="`${formName}-text-input-itn-input-${lang.locale}`"
                placement="bottom"
                model-value
                variant="light"
              >
                <small class="text-danger">{{ deleteFieldText(firstError) }}</small>
              </b-tooltip>
            </b-form-group>
          </Field>
        </b-tab>
        <template #empty>
          <div class="text-center text-muted">
            <b-form-input :id="`${formName}-text-input-itn-input-empty`" :placeholder="localNoLanguages" class="form-input" disabled />
          </div>
        </template>
      </b-tabs>
    </b-overlay>
  </div>
</template>

<script>
import { omit } from 'lodash'
import { useFieldItn } from '@/composables/useAppForm'

export default {
  name: 'AppFormTextInputItn',

  props: {
    formName: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: [String, Object],
      default: undefined,
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    languages: {
      type: Array,
      default: () => [],
    },
    defaultLang: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: '',
    },
    required: {
      type: Array,
      default: () => [],
    },
    noLanguages: {
      type: String,
      default: null,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    toolTipErrors: {
      type: Boolean,
      default: false,
    },
    vvName: { type: [Boolean, String], default: false },
  },
  emits: ['update:model-value', 'input-text'],

  setup(props) {
    const { firstError, getRules, isRequired, getFieldName, placeholderLocale } = useFieldItn(props)

    return { firstError, getRules, isRequired, getFieldName, placeholderLocale, omit }
  },

  computed: {
    localNoLanguages() {
      return this.noLanguages || this.$t('common.no_languages')
    },
    localDefaultLang() {
      return this.defaultLang || this.$store.state.auth.defaultLang
    },
  },
  mounted() {
    // Ajout de la langue de la plateforme en required par défaut
    this.required.push(localStorage.getItem('appLang'))
  },
  methods: {
    inputText(event, lang) {
      const payload = Object.assign(this.modelValue, { [lang]: event })
      this.$emit('update:model-value', payload)
      this.$emit('input-text', event)
    },

    focusOn(ref) {
      this.$nextTick(() => {
        this.$refs[ref][0].focus()
      })
    },
    deleteFieldText(msg) {
      const reg = new RegExp(`${this.formName}-${localStorage.getItem('appLang')}`, 'i')
      if (!this.vvName) return msg.replace(reg, '')
      if (this.vvName === true) {
        return msg.replace(reg, this.label || '')
      }
      return msg.replace(reg, this.vvName)
    }
  },
}
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 0.2857rem;
  display: block !important;
  color: #5e5873;
  font-size: 0.857rem;
}
.tab {
  margin-bottom: 0px;
}
.form-input {
  margin-top: -14px;
}
.title {
  margin-bottom: -3px;
}
.img {
  margin-right: 10px;
  height: 14px;
  width: 22px;
}

.tabs-start {
  min-width: 100px;
}

.dark-layout {
  .label {
    color: $theme-dark-body-color;
  }
}
</style>
