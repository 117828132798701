<template>
  <Field v-slot="{ field, errors }" ref="fieldRefTemplate" :model-value="modelValue" :name="id || ''" :rules="{ required }">
    <component
      :is="labelField ? 'b-form-group' : 'div'"
      :class="[{ 'validation-required':required, 'is-invalid': errors[0] }, classGroup]"
      :label="labelField"
      :label-for="id"
      :label-cols="labelCols"
      :label-cols-lg="labelColsLg"
      :label-cols-md="labelColsMd"
      :label-cols-sm="labelColsSm"
      :label-cols-xl="labelColsXl"
    >
      <b-overlay :show="overlay && !searchLoader" :variant="$store.state.appConfig.layout.skin === 'dark' ? 'transparent' : undefined">
        <v-select
          :input-id="id"
          :searchable="searchable"
          :placeholder="localPlaceholder"
          :taggable="taggable"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :close-on-select="!multiple"
          :multiple="multiple"
          v-bind="{...$attrs, ...field}"
          :model-value="modelValue"
          @update:model-value="field.onChange($event); $emit('update:model-value', $event)"
        >
          <!-- Load all v-select's scoped slots to be used from parent -->
          <template v-for="(_, name) in $slots" #[name]="slotData">
            <slot :name="name" v-bind="slotData || {}" />
          </template>

          <!-- required v-select -->
          <template v-if="required" #search="{ attributes, events }">
            <input
              class="vs__search"
              :required="!modelValue"
              v-bind="attributes"
              v-on="events"
            />
          </template>

          <template v-if="searchable" #no-options="{ search }">
            <b-spinner v-if="searchLoader" small />
            <template v-else-if="search">{{ $t('common.no_results_found_for', {search}) }}</template>
            <template v-else>{{ $t('common.no_results_found') }}</template>
          </template>

          <!-- Pagination into dropdown only if necessary -->
          <template v-if="needPagination" #list-footer="{ search }">
            <li class="pagination">
              <button
                class="w-100"
                type="button"
                :disabled="!previousPage"
                @click="$emit('click:previous-page', search || null)"
                v-text="$t('common.previous')"
              />
              <button
                class="w-100"
                type="button"
                :disabled="!nextPage"
                @click="$emit('click:next-page', search || null)"
                v-text="$t('common.next')"
              />
            </li>
          </template>
        </v-select>

        <template v-if="errors[0]">
          <small v-if="!toolTipErrors" class="text-danger">{{ deleteFieldText(errors[0]) }}</small>
          <b-tooltip v-else :target="id" placement="bottom" show variant="light">
            <small class="text-danger">{{ deleteFieldText(errors[0]) }}</small>
          </b-tooltip>
        </template>
      </b-overlay>
    </component>
  </Field>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'AppSelect',
  inheritAttrs: false,
}
</script>

<script setup>
defineEmits(['update:model-value', 'click:previous-page', 'click:next-page'])

const props = defineProps({
  modelValue: { type: [String, Number, Array, Object, Boolean], default: null },
  id: { type: String, default: undefined },
  labelField: { type: String, default: null },
  placeholder: { type: String, default: '' },
  classGroup: { type: String, default: '' },
  labelCols: { type: [String, Number], default: undefined },
  labelColsLg: { type: [String, Number], default: undefined },
  labelColsMd: { type: [String, Number], default: undefined },
  labelColsSm: { type: [String, Number], default: undefined },
  labelColsXl: { type: [String, Number], default: undefined },
  multiple: { type: Boolean, default: false },
  searchable: { type: Boolean, default: true },
  required: { type: Boolean, default: false },
  taggable: { type: Boolean, default: false },
  toolTipErrors: { type: Boolean, default: false },
  vvName: { type: [Boolean, String], default: true },
  overlay: { type: Boolean, default: false },
  paginated: { type: Boolean, default: false },
  perPage: { type: Number, default: 12 }, // default value api
  totalItems: { type: Number, default: 0 },
  previousPage: { type: Number, default: null },
  nextPage: { type: Number, default: null },
  lastPage: { type: Number, default: 1 },
})

const { t } = useI18n()

const searchLoader = ref(false)

const fieldRefTemplate = ref(null)

watch(() => props.overlay, val => {
  if (!val) fieldRefTemplate.value.reset({ value: props.modelValue })
})

const localPlaceholder = computed(() => {
  let placeholder = props.placeholder || props.labelField
  if (props.taggable) placeholder += ` | ${t('common.select_taggable')}`
  return placeholder
})

const deleteFieldText = msg => {
  const reg = new RegExp(`${props.id}`, 'i')
  if (!props.vvName) return msg.replace(reg, '')
  if (props.vvName === true) return msg.replace(reg, props.labelField || '')
  return msg.replace(reg, props.vvName)
}

const needPagination = computed(() => props.paginated && props.totalItems > props.perPage && !searchLoader.value && props.lastPage > 1)
</script>
