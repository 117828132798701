import { VueReCaptcha } from 'vue-recaptcha-v3'

export default {
  install(app) {
    app.use(VueReCaptcha, {
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      loaderOptions: {
        autoHideBadge: true,
      },
    })
  },
}
