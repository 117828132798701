import { ref } from 'vue'

const paramsConfirm = ref({
  content: '',
  options: {},
  props: {},
})

const msgBoxConfirm = (content, props, options = {}) => {
  paramsConfirm.value = {
    content,
    options,
    props: {
      ...paramsConfirm.value.props,
      ...props,
      modelValue: true,
    },
  }
}

const reset = () => {
  paramsConfirm.value = {
    constent: '',
    options: {},
    props: {},
  }
}

export default {
  paramsConfirm,
  msgBoxConfirm,
  reset,
}
