<template>
  <span
    :class="[
      'b-avatar',
      'bg-' + variant,
      rounded ? 'rounded-circle': ''
    ]"
    :style="`width: ${size}px; height: ${size}px;`"
  >
    <span class="b-avatar-custom">
      <BImg v-if="srcComputed" :src="srcComputed" :alt="text" class="b-avatar-img" @error="imageLoadOnError"></BImg>
      <span v-else-if="text" class="b-avatar-text"
            :style="`font-size: ${size*0.4}px;`">{{ text }}</span>
      <slot v-else>
        <font-awesome-icon icon="user" size="xl" />
      </slot>
    </span>
    <BBadge v-if="isBadgePassed" :variant="badgeVariant" class="b-avatar-badge" :style="badgeStyle" rounded>
      {{ badge }}
      <span v-if="!badge"></span>
    </BBadge>
  </span>
</template>

<script>
import { computed, ref } from 'vue'
import BBadge from '@/BoostrapConfiguration/BBadge.vue'
import BImg from '@/BoostrapConfiguration/BImg.vue'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {

  components: {
    BBadge,
    BImg,
    FontAwesomeIcon,
  },

  props: {
    variant: {
      type: String,
      default: 'secondary',
    },
    rounded: Boolean,
    src: String,
    text: String,
    size: String,
    badge: {
      type: [String, Boolean],
      default: undefined,
    },
    badgeVariant: String,
    badgeLeft: Boolean,
    badgeTop: Boolean,
    badgeOffset: String,
  },
  setup(props) {
    const badgeStyle = computed(() => {
      const style = {
        bottom: '0',
        top: 'unset',
        right: '0',
      }

      if (props.badgeTop) {
        style.top = props.badgeOffset
        style.bottom = 'auto'
      }

      if (props.badgeLeft) {
        style.left = props.badgeOffset
        style.right = 'auto'
      }

      return style
    })

    const isBadgePassed = computed(() => typeof props.badge !== 'undefined')
    const srcComputed = ref(props.src)

    const imageLoadOnError = e => {
      srcComputed.value = undefined
    }

    return {
      badgeStyle,
      isBadgePassed,
      imageLoadOnError,
      srcComputed,
    }
  },
}
</script>

<style scoped>
.b-avatar-badge {
  min-width: 15px;
  min-height: 15px;
}
</style>
