import i18n from '@/libs/i18n'
import { useToaster } from '@/composables/useToaster'

const _toastDelay = response => {
  let title = i18n.global.t('login.update_credential')
  if (response.data) {
    const fullName = (response.data.firstName && response.data.lastName)
      ? `${response.data.firstName} ${response.data.lastName}`
      : null

    title = fullName ? `${i18n.global.t('login.welcome')} ${fullName}` : i18n.global.t('registration.registration_user.welcome')
  }

  const { toaster } = useToaster()
  toaster(title, 'success', 'EditIcon')
}

const successHandler = response => {
  if (response.status >= 200 && response.status < 300) {
    let dontAlertUser = response.config.dontAlertUser || false
    if (!dontAlertUser) {
      setTimeout(_toastDelay(response), 250)
    }
    dontAlertUser = false
  }
  return response
}

export default successHandler
