import { createI18n } from 'vue-i18n'
import { unflatten } from 'flat'
import { omitBy } from 'lodash'
// import dateTimeFormats from '@/helpers/dateTimeFormats'
// import numberFormats from '@/helpers/numberFormats'
// eslint-disable-next-line import/no-cycle
import fetchMarketplacePublicConfigurationRequest
from '@/request/configApi/requests/marketplacePublicConfigurationRequests'

/**
 * @doc https://phrase.com/blog/posts/ultimate-guide-to-vue-localization-with-vue-i18n/#Async_Lazy_Loading_of_Translation_Files
 */

function loadLocaleMessages() {
  const locales = require.context(
    '../../translations',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('lang') || 'en',
  fallbackLocale: localStorage.getItem('appLang') || 'en',
  messages: loadLocaleMessages(),
  // dateTimeFormats,
  // numberFormats,
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  silentFallbackWarn: process.env.NODE_ENV === 'production',
})

export const initI18n = () => fetchMarketplacePublicConfigurationRequest().then(async response => {
  localStorage.setItem('appLang', response.data.appLang)
  i18n.fallbackLocale = response.data.appLang
  Object.entries(response.data.translations).forEach(([locale, translations]) => {
    const trans = omitBy(translations, val => !val)
    i18n.global.mergeLocaleMessage(locale, unflatten(trans))
    return i18n
  })
})

export default i18n
