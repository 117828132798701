<template>
  <component
    :is="label || classGroup ? 'b-form-group' : 'div'"
    :class="[{ 'validation-required': isRequired }, classGroup]"
    :label="label || undefined"
    :label-for="label ? id : undefined"
    :label-cols="labelCols"
    :label-cols-lg="labelColsLg"
    :label-cols-md="labelColsMd"
    :label-cols-sm="labelColsSm"
    :label-cols-xl="labelColsXl"
  >
    <b-overlay :show="overlay" :variant="$store.state.appConfig.layout.skin === 'dark' ? 'transparent' : undefined">
      <b-input-group :class="classInputGroup" :style="styleGroup">
        <b-form-input
          :id="id"
          :ref="`AppInputPassword-${id}`"
          v-bind="$attrs"
          :title="localTitle"
          :class="[classInput]"
          :style="styleInput"
          :state="errors[0] ? false : state"
          :placeholder="placeholder !== false ? placeholder || label : undefined"
          :required="isRequired"
          :model-value="value"
          :type="passwordFieldType"
          @update:model-value="textChange($event)"
          @focus="$emit('focus')"
        />
        <b-input-group-append is-text @click="togglePasswordVisibility">
          <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" />
        </b-input-group-append>
      </b-input-group>
    </b-overlay>
    <small v-if="!hideErrors && errors[0]" class="text-danger">
      {{ deleteFieldText(errors[0]) }}
    </small>
    <slot name="error" :error="errors[0] && deleteFieldText(errors[0])" />
  </component>
</template>

<script setup>
import { usePassword } from '@/composables/useAppForm'
import {computed} from "vue";
import { useField } from 'vee-validate'

defineOptions({
  name: 'AppInputPassword',
  inheritAttrs: false,
})

const emit = defineEmits(['update:modelValue', 'focus'])

const { passwordFieldType, togglePasswordVisibility, passwordToggleIcon } = usePassword()

const props = defineProps({
  modelValue: { type: [Number, String], default: '' },
  id: { type: String, required: true },
  // eslint-disable-next-line vue/require-prop-types
  classGroup: { type: String, default: undefined },
  styleGroup: { type: Object, default: () => ({}) },
  classInputGroup: { type: String, default: undefined },
  classInput: { type: String, default: undefined },
  styleInput: { type: Object, default: () => ({}) },
  vvName: { type: [Boolean, String], default: false },
  label: { type: String, default: '' },
  title: { type: [Boolean, String], default: null },
  labelCols: { type: [String, Number], default: undefined },
  labelColsLg: { type: [String, Number], default: undefined },
  labelColsMd: { type: [String, Number], default: undefined },
  labelColsSm: { type: [String, Number], default: undefined },
  labelColsXl: { type: [String, Number], default: undefined },
  overlay: { type: Boolean, default: false },
  placeholder: { type: String, default: undefined },
  rules: { type: String, default: '' },
  required: { type: Boolean, default: false },
  hideErrors: { type: Boolean, default: false },
  state: { type: Boolean, default: null },
})

const isRequired = computed(() => props.rules.includes('required') || props.required)

const localTitle = computed(() => {
  if (typeof props.title === 'string') return props.title
  if (props.title && props.modelValue) return props.placeholder
  return ''
})

const computedRules = computed(() => {
  let normalizeRules = props.rules
  if (isRequired.value && !normalizeRules.includes('required')) {
    if (!normalizeRules) normalizeRules = 'required'
    else normalizeRules += '|required'
  }

  return normalizeRules
})

const { errors, value } = useField(() => props.id, computedRules, {
  syncVModel: true,
})

const deleteFieldText = msg => {
  const reg = new RegExp(props.id, 'i')
  if (!props.vvName) return msg.replace(reg, '')
  if (props.vvName === true) return msg.replace(reg, props.label || '')
  return msg.replace(reg, props.vvName)
}

const textChange = val => {
  let inputValue = val
  if (value.value !== inputValue) {
    if (inputValue === '' || Number.isNaN(inputValue)) inputValue = null
  }
  value.value = inputValue
}
</script>
