<template>
  <b-v-table
    :items="typeof items !== 'function' ? items : localeItems"
    :sort-by="newSortBy"
    :current-page="!manualPagination && typeof items !== 'function' ? currentPage : 1"
    :responsive="responsive"
    :show-empty="showEmpty"
    :per-page="perPage"
    :multisort="multisort"
    :hover="hover"
    @sorted="onSorted"
    @update:sort-by="onSortBy"
  >
    <!-- Load all scoped slots to be used from parent -->
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData || {}" />
    </template>
  </b-v-table>
</template>

<script>
import { computed, ref, watch } from 'vue'

export default {
  name: 'BTableExtended',
}
</script>

<script setup>
import { BTable as BVTable } from 'bootstrap-vue-next'

const sortBy = defineModel('sortBy', { type: String, default: undefined })
const sortDesc = defineModel('sortDesc', { type: Boolean, default: undefined })

const props = defineProps({
  responsive: {
    type: Boolean,
    default: true,
  },
  showEmpty: {
    type: Boolean,
    default: true,
  },
  hover: {
    type: Boolean,
    default: true,
  },
  items: {
    type: [Array, Function],
    default: undefined,
  },
  searchText: {
    type: String,
    default: undefined,
  },
  currentPage: {
    type: Number,
    default: undefined,
  },
  perPage: {
    type: Number,
    default: undefined,
  },
  manualPagination: {
    type: Boolean,
    default: false,
  },
  multisort: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:sort-by', 'update:sort-desc', 'sort-changed', 'update:overlay'])

const getOrder = val => (val ? 'desc' : 'asc')
const getSortDesc = val => val === 'desc'

const newSortBy = computed(() => {
  if (!sortBy.value || sortDesc.value === undefined) return []
  return [{ key: sortBy.value, order: getOrder(sortDesc.value) }]
})

const onSortBy = evt => {
  if (!evt[0]?.key) {
    sortBy.value = undefined
    sortDesc.value = undefined
  } else {
    const { key, order } = evt[0]
    sortBy.value = key
    sortDesc.value = getSortDesc(order)
  }
}

const onSorted = evt => {
  const payload = { sortBy: evt.key, sortDesc: getSortDesc(evt.order) }
  emit('sort-changed', payload)
}

const localeItems = ref()

// ? ITEMS: Run only if props.items is a function. Else, items's b-table prop = props.items <Array>
const getItems = () => {
  emit('update:overlay', true)
  // Note for Axios: queryparams to null are not sent
  const btableMeta = {
    currentPage: props.currentPage || 1,
    perPage: props.perPage,
    filter: props.searchText || null,
    computedSort: {},
  }
  if (sortBy.value) btableMeta.computedSort[`${sortBy.value}Order`] = getOrder(sortDesc.value)

  const setItems = payload => {
    localeItems.value = payload
    emit('update:overlay', false)
  }
  props.items(btableMeta, setItems)
}

if (typeof props.items === 'function') {
  watch([() => props.currentPage, () => props.perPage, sortBy, sortDesc], () => {
    getItems()
  }, { immediate: true })
}
defineExpose({
  localItems: typeof props.items !== 'function' ? props.items : localeItems.value,
  refresh: getItems,
})
</script>
