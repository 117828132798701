/* eslint-disable no-console */

import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'

// 3RD PARTY LIBRARIES
import { Field } from 'vee-validate'
import BootstrapVueComponents from '@/libs/boostrap-vue'
import PortalVue from '@/libs/portal-vue'
import vSelect from '@/libs/vue-select'
import toastification from '@/libs/toastification'
import '@/libs/stripes'
import '@/libs/vee-validate'
import googleMaps from '@/libs/google-maps'
import flatPickr from '@/libs/flat-pickr'
import CustomComponents from '@/libs/custom-components'
import fontAwesomeIcon from '@/libs/font-awesome-icon'
import vueFontAwesomePicker from '@/libs/vue-font-awesome-picker'
import recaptcha from '@/libs/recaptcha'
import bootstrapVueNext from 'bootstrap-vue-next'

// DIRECTIVES
import BTooltipDirective from '@/directives/b-tooltip-directive'
import vRipple from '@/directives/v-ripple'

// LEGACY LIBRARIES
import store from '@/store/store'
import router from '@/router/router'

// MAJOR LIBRARIES
import i18n from '@/libs/i18n'
import { abilitiesPlugin } from '@casl/vue'
import ability from '@/libs/ability'
import prototype from '@/prototype'
import VueFeather from 'vue-feather'
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle'
import globalPlugins from './plugins/global-plugins'

import App from './App.vue'

import alertMixin from './helpers/alert'

// COMPONENTS
import 'vue3-tel-input/dist/vue3-tel-input.css'

// SCSS
require('@/assets/scss/core.scss')
require('@/assets/scss/style.scss')
// eslint-disable-next-line import/no-unresolved, import/no-absolute-path
require('/node_modules/bootstrap-vue-next/dist/bootstrap-vue-next.css')
// require('@/assets/scss/bootstrap-next.scss')

const app = createApp(App)

// ERRORS LOGING AND TRACKING
if (process.env.NODE_ENV !== 'production') {
  app.config.errorHandler = err => {
    console.error(err)
  }

  app.config.productionTip = true
} else {
  app.config.productionTip = false
}

if (process.env.VUE_APP_SENTRY_DSN && process.env.VUE_APP_SENTRY_ENV) {
  Sentry.init({
    app,
    environment: process.env.VUE_APP_SENTRY_ENV,
    release: process.env.VUE_APP_SENTRY_RELEASE,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    trackComponents: true,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: process.env.VUE_APP_REPLAY_SENTRY_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: process.env.VUE_APP_REPLAY_SENTRY_ON_ERROR_SAMPLE_RATE,
  })
}

// BSV Plugin Registration
// app.use(createBootstrap()) // bootstrap-vue-next

// register Swiper custom elements (web components)
register()

app.use(BootstrapVueComponents)
  .use(CustomComponents)
  .use(fontAwesomeIcon)
  .use(vueFontAwesomePicker)
  .use(toastification)
  .use(googleMaps)
  .use(flatPickr)
  .use(globalPlugins)
  .use(prototype)
  .use(PortalVue)
  .use(i18n)
  .use(store)
  .use(router)
  .use(recaptcha)
  .use(bootstrapVueNext({ plugins: { modalController: true } }))
  .use(abilitiesPlugin, ability, { useGlobalProperties: true })

  .mixin(alertMixin)
  .component('Field', Field)
  .component('VSelect', vSelect)
  .component('VueFeather', VueFeather)

  // Directives
  .use(BTooltipDirective)
  .directive('ripple', vRipple)

  .mount('#app')
